import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';

export default class AdminProxyService {
	static login = async (loginData: {name: string; password: string}) => {
		try {
			const {data, status} = await axios.post(apiPath.login, loginData);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getProjectName = async (token: UUID) => {
		try {
			const apiUrl = apiPath.projectInfo;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateProjectName = async (token: UUID, name: string) => {
		try {
			const apiUrl = apiPath.projectInfo;
			const {data, status} = await axios.patch(
				apiUrl,
				{name},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAdmins = async (token: UUID) => {
		try {
			const apiUrl = apiPath.getAdmins;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAdmin = async (token: UUID, userId: number) => {
		try {
			const apiUrl = apiPath.getAdmin(userId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteProjectUser = async (id: number, token: UUID) => {
		try {
			const apiUrl = apiPath.deleteProjectUser(id);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addAdmin = async (
		adminData: {name: string; password: string; role: string},
		token: UUID
	) => {
		try {
			const apiUrl = apiPath.addAdmin;
			const body = {
				name: adminData.name,
				password: adminData.password,
				role: adminData.role,
			};
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static updateAdmin = async (
		token: UUID,
		adminData: {name?: string; chatName?: string; password?: string}
	) => {
		try {
			const apiUrl = apiPath.updateAdmin;

			const {data, status} = await axios.patch(apiUrl, adminData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updatePassword = async (token: UUID, oldPassword: string, password: string) => {
		try {
			const apiUrl = apiPath.changeUserPassword;
			const body = {
				oldPassword,
				password,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static updateProjectUser = async (token: UUID, id: number, adminData: {role: string}) => {
		try {
			const apiUrl = apiPath.updateProjectUser(id);

			const {data, status} = await axios.patch(apiUrl, adminData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSelf = async (token: UUID) => {
		try {
			const apiUrl = apiPath.getSelf;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateAdminPic = async (token: UUID, pic?: File) => {
		try {
			const apiUrl = apiPath.getSelf;
			const formData = new FormData();
			if (pic) formData.append('pic', pic);

			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import {isJsonString} from 'utils/helpers';
import userServices from 'store/userServices';
import appService from 'store/appService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Theme} from 'models/enums/Theme.enum';
import SettingsService from 'services/api/SettingsService';

interface IColors {
	currentTab: string;
}

const Colors: FunctionComponent<IColors> = function Colors({currentTab}) {
	const translations = useL10n();
	const {theme, themeColors, currentThemeId, settings} = useLocalObservable(() => settingsServices);
	const {accessToken, chatUserId, adminData, setChatUserId} = useLocalObservable(
		() => userServices
	);
	const {projectApiKey, setProjectApiKey, projectId} = useLocalObservable(() => appService);

	const [myColors, setMyColors] = useState<any>('');
	const [error, setError] = useState('');
	const [isChanged, setIsChanged] = useState(false);
	const [nickname, setNickname] = useState('');
	const [image, setImage] = useState(
		'https://storage.yandexcloud.net/matchtv/4e874821-3ac0-447c-ac2e-e79c15c921d5.jpeg'
	);
	const [iframeKey, setIframeKey] = useState(0);

	const {getColors, setColors} = useSettings();

	const chatUrl = process.env.REACT_APP_CHAT_URL;

	const resetIframe = () => {
		setIframeKey(iframeKey + 1);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMyColors(value);
		setError('');
		setIsChanged(themeColors !== value);
	};

	const onClickSaveHandler = async () => {
		if (!isJsonString(myColors)) {
			setError('Invalid json');
			return;
		}
		setError('');
		setIsChanged(false);
		currentThemeId && (await setColors(currentThemeId, myColors));
		resetIframe();
	};

	const getChatUrl = () => {
		let url = `${chatUrl}/?roomId=testcolors&userId=${chatUserId}&apikey=${projectApiKey}&mode=${
			currentThemeId === 1 ? Theme.LIGHT.toLowerCase() : Theme.DARK.toLowerCase()
		}`;
		if (settings.enableRegistrationName) url = `${url}&nickname=${nickname}`;
		if (settings.enableRegistrationPicUrl && projectId.includes('matchtv'))
			url = `${url}&image=${image}`;
		return url;
	};

	const getApiKey = async () => {
		const response = await SettingsService.getApiKey(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setProjectApiKey(response.data);
		}
	};

	useEffect(() => {
		setMyColors(themeColors);
		setError('');
	}, [themeColors, currentTab]);

	useEffect(() => {
		currentTab === 'colors' && currentThemeId && getColors(currentThemeId);
	}, [theme, currentTab, currentThemeId]);

	useEffect(() => {
		if (accessToken) {
			!projectApiKey && getApiKey();
		}
	}, [accessToken]);

	useEffect(() => {
		if (adminData) {
			setNickname(adminData.name);
			setChatUserId(adminData.externalId);
		}
	}, [adminData]);

	useEffect(() => {
		return () => {
			setMyColors('');
		};
	}, []);

	return (
		<div className='block mb-5 settings__colors'>
			<p className='h5 mb-4'>{translations.settings.colors}</p>
			<div className='row'>
				<div className='col-6 settings__colors-textarea'>
					<div className='position-relative pt-4'>
						{error && <b className='text-danger position-absolute top-0'>{error}</b>}
						<Form.Control
							as='textarea'
							rows={30}
							size='sm'
							placeholder={translations.settings.colors}
							className='h-100 mb-3'
							value={myColors}
							onChange={onChangeHandler}
						/>
					</div>

					<Button
						type='button'
						className='mb-3'
						variant='success'
						disabled={!isChanged}
						onClick={onClickSaveHandler}>
						{translations.btns.save}
					</Button>
				</div>
				<div className='col-6 settings__colors-iframe'>
					<iframe
						key={iframeKey}
						title='chat'
						src={getChatUrl()}
						frameBorder='0'
						width='100%'
						height='100%'
						allow='clipboard-read; clipboard-write; microphone'
					/>
				</div>
			</div>
		</div>
	);
};
export default observer(Colors);

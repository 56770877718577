import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';

import appService from 'store/appService';

import SelectControl from 'components/select/SelectControl';

interface ISelectProject {
	projectUsers: any[];
	sidebar?: boolean;
}
const projectSelect: FunctionComponent<ISelectProject> = function projectSelect({
	projectUsers,
	sidebar,
}) {
	const history = useHistory();
	const [selectedProject, setSelectedProject] = useState<any>();
	const [options, setOptions] = useState<any>([]);

	const {projectId, setProjectId} = useLocalObservable(() => appService);

	const getUserBySelectedProject = (project: string) => {
		return projectUsers.find((el: any) => el.projectId === project);
	};

	const setUserData = async (project: string) => {
		setProjectId(project);
		localStorage.setItem('projectId', project);
		localStorage.setItem('user', JSON.stringify(getUserBySelectedProject(project)));
	};

	const setCurrentProject = () => {
		setSelectedProject({
			value: projectId,
			label: projectUsers.find(el => el.projectId === projectId)?.project.name || projectId,
		});
	};

	const getItemsForSelect = () => {
		const elems = projectUsers.map(el => {
			return {value: el.projectId, label: el.project.name};
		});
		setOptions(elems);
		projectUsers && setCurrentProject();
	};

	const handleChange = (selectedOption: any) => {
		setSelectedProject(selectedOption);
		setUserData(selectedOption.value);
		if (sidebar) {
			window.location.reload();
		}
	};

	useEffect(() => {
		if (sidebar) getItemsForSelect();
	}, [projectUsers]);

	useEffect(() => {
		if (!sidebar) setProjectId(projectUsers[0].projectId);
		getItemsForSelect();
	}, []);

	useEffect(() => {
		if (projectId) setCurrentProject();
	}, [projectId]);

	return (
		<SelectControl
			handleChange={handleChange}
			options={options}
			value={selectedProject}
			theme='dark'
		/>
	);
};

export default observer(projectSelect);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {Container, Row, Col, Table, Breadcrumb, Button, Badge, Spinner} from 'react-bootstrap';
import {BsTrashFill, BsFillPencilFill} from 'react-icons/bs';

import alertService from 'store/alertServices';
import modalServices from 'store/modalServices';
import toastService from 'store/toastServices';
import userServices from 'store/userServices';

import ChangeRoleModal from 'components/modals/changeRole/ChangeRoleModal';
import CreateAdminModal from 'components/modals/createAdmin/CreateAdminModal';

import useL10n from 'l10n/useL10n';
import UserRole from 'models/enums/UserRole.enum';
import useAdminProxy from 'hooks/useAdminProxy';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import PageTitle from 'components/pageTitle/PageTitle';

const Admin: FunctionComponent = function Admin() {
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);
	const {showAdminModal, showRoleModal} = useLocalObservable(() => modalServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [admins, setAdmins] = useState([]);
	const [currentAdmin, setCurrentAdmin] = useState('');
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const translations = useL10n();

	const {getAdmins, deleteProjectUser} = useAdminProxy();

	const getAdminsWithServices = async () => {
		if (accessToken) {
			setVisiblePreloader(true);
			const response = await getAdmins();
			setVisiblePreloader(false);
			if (response.status === ResponseStatus.SUCCESS) setAdmins(response.data);
		}
	};

	const deleteAdmin = async (id: number, name: string) => {
		let responseText;
		const response = await deleteProjectUser(id);
		if (response.status === ResponseStatus.ERROR) {
			responseText = translations.toasts.error;
			addToast({
				title: translations.toasts.admin(name),
				text: responseText,
				variant: response.status === ResponseStatus.ERROR ? 'danger' : 'success',
			});
			return;
		}
		if (response.status === ResponseStatus.SUCCESS) {
			getAdminsWithServices();
		}
	};

	const openAlert = (name: string, id: number) => {
		showAlert({
			title: translations.sidebar.admin,
			text: translations.alerts.deleteUser(name),
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.delete,
					type: AlertBtnType.DANGER,
					onClick: () => {
						deleteAdmin(id, name);
						hideAlert();
					},
				},
			],
		});
	};

	const showToastAdmin = () => {
		getAdminsWithServices();
		addToast({
			title: translations.toasts.createModer,
			text: translations.toasts.created,
			variant: 'success',
		});
	};

	const showToastRole = () => {
		getAdminsWithServices();
		addToast({
			title: translations.toasts.changeRole,
			text: translations.toasts.roleChanged,
			variant: 'success',
		});
	};

	const openAlertError = (errorText: string) => {
		showAlert({
			title: translations.toasts.createModer,
			text: errorText,
			buttons: [
				{
					text: translations.alerts.btns.ok,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const changeRoleHandler = (admin: string) => {
		setCurrentAdmin(admin);
		showRoleModal();
	};

	useEffect(() => {
		getAdminsWithServices();
	}, [accessToken]);

	const renderAdmin = useCallback(
		(item: any, index: any) => {
			return (
				<tr key={index}>
					<td className='align-middle'>{item.user.name}</td>
					<td className='col-2 align-middle'>
						<Badge bg='secondary'>
							{item.role === UserRole.ADMIN ? translations.roles.admin : translations.roles.moder}
						</Badge>
						<Button
							className='m-1'
							variant='outline'
							size='sm'
							onClick={() => changeRoleHandler(item)}>
							<BsFillPencilFill />
						</Button>
					</td>
					<td className='col-1 text-center align-middle'>
						<Button variant='danger' size='sm' onClick={() => openAlert(item.user.name, item.id)}>
							<BsTrashFill />
						</Button>
					</td>
				</tr>
			);
		},
		[admins]
	);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.admin}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.admins.title}
						text={translations.sidebar.admins.descr}
					/>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{admins.length > 0 ? (
						<Table
							responsive
							striped={appTheme === Theme.LIGHT}
							bordered
							hover
							variant={appTheme?.toLowerCase()}
							className='mb-5'>
							<thead>
								<tr>
									<th>
										<b>{translations.table.login}</b>
									</th>
									<th className='col-2 '>
										<b>{translations.table.role}</b>
									</th>
									{/* <th className='col-2 text-center'>
										<b>{translations.table.changePassword}</b>
									</th> */}
									<th className='col-1 text-center'>
										<b> </b>
									</th>
								</tr>
							</thead>
							<tbody>{admins.map(renderAdmin)}</tbody>
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.modersEmpty}</p>
					)}
					{admins.length > 0 && !visiblePreloader && (
						<Button className='mb-4' variant='dark' onClick={showAdminModal}>
							{translations.btns.addModer}
						</Button>
					)}
				</Col>
			</Row>
			<CreateAdminModal
				onCloseSuccess={(role, name) => showToastAdmin()}
				onCloseError={error => openAlertError(error)}
			/>
			<ChangeRoleModal
				admin={currentAdmin}
				onCloseSuccess={showToastRole}
				onCloseError={error => openAlertError(error)}
			/>
		</Container>
	);
};

export default observer(Admin);

import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class RulesService {
	static getRules = async (token: UUID) => {
		try {
			const apiUrl = apiPath.chatRules;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addRules = async (
		token: UUID,
		value: {
			rulesHTML?: string;
			policyHTML?: string;
			lang: string;
		}
	) => {
		try {
			const apiUrl = apiPath.chatRules;

			const {data, status} = await axios.post(apiUrl, value, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateRules = async (
		token: UUID,
		id: number,
		value: {
			rulesHTML?: string;
			policyHTML?: string;
			lang: string;
		}
	) => {
		try {
			const apiUrl = apiPath.updateRules(id);

			const {data, status} = await axios.patch(apiUrl, value, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteRules = async (token: UUID, id: number) => {
		try {
			const apiUrl = apiPath.updateRules(id);

			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

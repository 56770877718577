import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect} from 'react';

import {Button, Modal, Form, FloatingLabel} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import {millisToMinutesOrSeconds} from 'utils/helpers';
import modalServices from 'store/modalServices';
import {Project} from 'models/enums/Project.enum';
import userServices from 'store/userServices';
import appService from 'store/appService';
import roomServices from 'store/roomServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import RoomService from 'services/api/RoomService';
import toastServices from 'store/toastServices';
import SettingsService from 'services/api/SettingsService';
import settingsServices from 'store/settingsServices';

interface IRoomSettingsModal {
	room: any;
	slug: string;
}

const RoomSettingsModal: FunctionComponent<IRoomSettingsModal> = function RoomSettingsModal({
	room,
	slug,
}) {
	const translations = useL10n();

	const {projectId} = useLocalObservable(() => appService);
	const {accessToken, isAdmin} = useLocalObservable(() => userServices);
	const {roomSettingsModalVisible, toggleRoomSettingsModalVisible, showAgoraRecordModal} =
		useLocalObservable(() => modalServices);
	const {addToast} = useLocalObservable(() => toastServices);

	const {isSpeak, setIsSpeak, isSlowmode, setIsSlowmode, slowmodeTimeout, setSlowmodeTimeout} =
		useLocalObservable(() => roomServices);

	const {settings, setSettings} = useLocalObservable(() => settingsServices);

	const project = projectId.split('-')[0];

	const getSettings = async () => {
		const response = await SettingsService.getSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setSettings(response.data);
		}
	};

	const onModalShow = () => {
		getSettings();
	};

	const onModalClose = () => {
		toggleRoomSettingsModalVisible(false);
	};

	const toggleRoomSpeak = async (value: boolean) => {
		const response = await RoomService.toggleRoomSpeak(
			accessToken,
			room?.externalRoomId || slug,
			value
		);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const toggleRoomSlowmode = async (value: boolean) => {
		const response = await RoomService.toggleRoomSlowmode(
			accessToken,
			room?.externalRoomId || slug,
			value
		);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const setRoomSlowmodeTimeout = async (value: number) => {
		const response = await RoomService.setRoomSlowmodeTimeout(
			accessToken,
			room?.externalRoomId || slug,
			value
		);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const onSwitchIsSpeak = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsSpeak(!isSpeak);
		toggleRoomSpeak(checked);
	};

	const onSwitchIsSlowmode = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsSlowmode(!isSlowmode);
		toggleRoomSlowmode(checked);
		if (room.slowmodeDelayMS === 0) setRoomSlowmodeTimeout(1000);
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSlowmodeTimeout(+value);
		setRoomSlowmodeTimeout(+value);
	};

	const onClickAgoraModalHandler = () => {
		toggleRoomSettingsModalVisible(false);
		showAgoraRecordModal();
	};

	return (
		<Modal
			className='modal--dark'
			show={roomSettingsModalVisible}
			onHide={onModalClose}
			onShow={onModalShow}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton className='text-primary'>
				<Modal.Title className='text-primary'> {translations.btns.roomSettings} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex align-items-center justify-content-between mb-3'>
					{isAdmin &&
						!settings.enableRoomSpeak &&
						(project === Project.TINKOFF ||
							project === Project.WINK ||
							project === Project.MATCHTV ||
							project.includes('bitazzadev') ||
							project === 'stand' ||
							project === 'stand-dev' ||
							project === 'stage' ||
							project === 'dev') && (
							<div className='text-white'>
								<Form.Check
									onChange={onSwitchIsSpeak}
									type='switch'
									id='speakToggle'
									label={translations.room.switch}
									checked={isSpeak}
								/>
							</div>
						)}
					{isAdmin && (isSpeak || settings.enableRoomSpeak) && (
						<>
							&nbsp; &nbsp;
							<Button size='sm' onClick={onClickAgoraModalHandler}>
								{translations.btns.voiceRecord}
							</Button>
						</>
					)}
				</div>
				<hr className='text-white' />
				<div className='text-white mb-4'>
					<Form.Check
						onChange={onSwitchIsSlowmode}
						type='switch'
						id='slowmodeToggle'
						label={
							isSlowmode ? translations.room.disableSlowMode : translations.room.enableSlowMode
						}
						checked={isSlowmode}
					/>
					<div className='p-4 pt-0 pb-0'>
						{isSlowmode && settings.enableSlowmode && (
							<>
								<small>({translations.room.disableSlowmodeWarning}, </small>
								<small>
									{translations.room.slowmodeSettingsDelay}:{' '}
									{settings.slowmodeDelayMS && millisToMinutesOrSeconds(settings.slowmodeDelayMS)})
								</small>
							</>
						)}
					</div>
				</div>
				<FloatingLabel controlId='floatingSelect' label={translations.room.slowmodeDelay}>
					<Form.Select disabled={!isSlowmode} onChange={onSelect} value={slowmodeTimeout}>
						<option value='1000'>1 {translations.sec}</option>
						<option value='2000'>2 {translations.sec}</option>
						<option value='3000'>3 {translations.sec}</option>
						<option value='5000'>5 {translations.sec}</option>
						<option value='10000'>10 {translations.sec}</option>
						<option value='15000'>15 {translations.sec}</option>
						<option value='20000'>20 {translations.sec}</option>
						<option value='30000'>30 {translations.sec}</option>
						<option value='60000'>1 {translations.min}</option>
						<option value='120000'>2 {translations.min}</option>
						<option value='180000'>3 {translations.min}</option>
						<option value='300000'>5 {translations.min}</option>
					</Form.Select>
				</FloatingLabel>
			</Modal.Body>
		</Modal>
	);
};

export default observer(RoomSettingsModal);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form, Tabs, Tab} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import AutoHideMessagesEnum from 'models/enums/AutoHideMessages.enum';
import {MessagePicsModerationType} from 'models/enums/MessagePicsModerationType.enum';

const Automoderation: FunctionComponent = function Automoderation() {
	const translations = useL10n();

	const {settings} = useLocalObservable(() => settingsServices);

	const [isDBRT, setIsDBRT] = useState(settings?.enableToxicityCheckDBRT);
	const [isOPAI, setIsOPAI] = useState(settings?.enableToxicityCheckOPAI);
	const [autoHide, setAutoHide] = useState(settings?.autoHideMessages);
	const [isAutobansEnabled, setIsAutobansEnabled] = useState(settings?.enableAutobans);
	const [isEnableMessagePicsModeration, setIsEnableMessagePicsModeration] = useState(
		settings?.enableMessagePics
	);
	const [messagePicsModerationType, setMessagePicsModerationType] =
		useState<MessagePicsModerationType>(
			settings?.messagePicsModerationType as MessagePicsModerationType
		);
	const [key, setKey] = useState('automoderation');
	const [categories, setCategories] = useState<any>(null);

	const {search} = useLocation();

	const watchersInstanceFromUrl = new URLSearchParams(search).get('instance');
	const watchersAppInstance =
		process.env.REACT_APP_INSTANCE === 'watchers' || watchersInstanceFromUrl === 'watchers';

	const {patchSettings} = useSettings();

	const checkForAutohide = () => {
		if (isDBRT && !watchersAppInstance) return AutoHideMessagesEnum.TOXICITY_DBRT;
		if (isOPAI && watchersAppInstance) return AutoHideMessagesEnum.TOXICITY_OPAI;
		return null;
	};

	const onChangeDBRTHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsDBRT(checked);
		patchSettings({
			enableToxicityCheckDBRT: checked,
		});
	};

	const onChangeOPAIHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsOPAI(checked);
		patchSettings({
			enableToxicityCheckOPAI: checked,
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name, value} = event.target;

		patchSettings({
			[name]: checked,
		});
	};

	const onChangeMessagePicsModerationHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name, value} = event.target;

		setMessagePicsModerationType(value as MessagePicsModerationType);
		patchSettings({
			[name]: value,
		});
	};

	const onChangeAutoHideHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;

		patchSettings({
			autoHideMessages: checked ? checkForAutohide() : null,
		});
	};

	const onChangeValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value: any = event.target.value.replace(/[^\d.]/g, '');

		value = value.replace(/^([^.]*\.)(.*)$/, (a: any, b: any, c: any) => {
			return b + c.replace(/\./g, '');
		});

		value = +value;

		if (value > 100) value = 100;

		const updatedData = categories.map((item: {category: string; value: number}) => {
			if (item.category === event.target.name) {
				return {...item, value};
			}
			return item;
		});

		setCategories(updatedData);
	};

	const arrayToObject = (array: []) => {
		return array.reduce((acc, {category, value}) => {
			// eslint-disable-next-line no-param-reassign
			acc[category] = value;
			return acc;
		}, {});
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		let value = +event.target.value;
		if (value === 0) value = 1;

		let updatedData = categories.map((item: {category: string; value: number}) => {
			if (item.category === event.target.name) {
				return {...item, value};
			}
			return item;
		});

		setCategories(updatedData);
		updatedData = updatedData.map((item: {category: string; value: number}) => {
			return {...item, value: item.value / 100};
		});
		patchSettings({
			toxicityValues: JSON.stringify(arrayToObject(updatedData)),
		});
	};

	const objectToArray = (obj: any) => {
		return Object.entries(obj).map(([objKey, objValue]) => ({
			category: objKey,
			value: Math.round(Number(objValue) * 100),
		}));
	};

	useEffect(() => {
		setIsDBRT(settings?.enableToxicityCheckDBRT);
		setIsOPAI(settings?.enableToxicityCheckOPAI);
		setAutoHide(settings?.autoHideMessages);
		setIsAutobansEnabled(settings?.enableAutobans);
		setIsEnableMessagePicsModeration(settings?.enableMessagePicsModeration);
		setMessagePicsModerationType(settings?.messagePicsModerationType as MessagePicsModerationType);
		if (settings?.toxicityValues) {
			setCategories(objectToArray(JSON.parse(settings.toxicityValues)));
		}
	}, [settings]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.automoderation}</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h3 mb-5'>{translations.sidebar.automoderation}</p>
					<Tabs
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						defaultActiveKey='automoderation'
						id='uncontrolled-tab-example'
						className='nav-tabs mb-3'
						variant='dark'>
						<Tab
							eventKey='automoderation'
							title={translations.sidebar.automoderation}
							tabClassName='text-secondary'>
							<div className='block w-50 mb-4'>
								<p className='h5 mb-3'>{translations.settings.toxicityEstimation}</p>
								<p className='text-secondary'>{translations.settings.toxicityEstimationText}</p>
								<div className='mb-5'>
									{!watchersAppInstance && (
										<div className='mb-3'>
											<Form.Check
												onChange={onChangeDBRTHandler}
												type='switch'
												id='DBRTToggle'
												label={translations.settings.useDBRTAutomoderationToggle}
												checked={isDBRT}
											/>
										</div>
									)}
									{watchersAppInstance && (
										<div>
											<Form.Check
												onChange={onChangeOPAIHandler}
												type='switch'
												id='OPAIToggle'
												label='Use OPAI (beta)'
												checked={isOPAI}
											/>
										</div>
									)}
								</div>
								<p className='h5 mb-3'>{translations.settings.autohide}</p>
								<p className='text-secondary'>{translations.settings.autohideText}</p>
								<div className='mb-5'>
									<Form.Check
										onChange={onChangeAutoHideHandler}
										type='switch'
										id='autohideToggle'
										label={translations.settings.autohideToggle}
										checked={autoHide !== null}
										disabled={(!watchersAppInstance && !isDBRT) || (watchersAppInstance && !isOPAI)}
									/>
								</div>
							</div>
							<div className='block w-50 mb-4'>
								<p className='h5 mb-3'>{translations.settings.userModeration}</p>
								<div className='mb-3'>
									<p className='text-secondary'>{translations.settings.enableAutobans.descr}</p>
									<Form.Check
										onChange={onChangeHandler}
										type='switch'
										name='enableAutobans'
										id='enableAutobansToggle'
										label={translations.settings.enableAutobans.title}
										checked={isAutobansEnabled}
									/>
								</div>
							</div>
							<div className='block w-50 mb-4'>
								<p className='h5 mb-4'>{translations.settings.imageModeration}</p>
								<div className='mb-4'>
									<Form.Check
										onChange={onChangeHandler}
										type='switch'
										name='enableMessagePicsModeration'
										id='enableMessagePicsModerationToggle'
										label={translations.settings.enableMessagePicsModeration}
										checked={isEnableMessagePicsModeration}
									/>
								</div>
								<Form.Group className='mb-3' controlId='messagePicsModerationType'>
									<div>
										<Form.Check
											inline
											className='checkbox-black mb-2'
											name='messagePicsModerationType'
											type='radio'
											label={translations.settings.preModeration.title}
											id='PRE'
											value={MessagePicsModerationType.PRE}
											required
											onChange={onChangeMessagePicsModerationHandler}
											checked={messagePicsModerationType === MessagePicsModerationType.PRE}
										/>
										<p className='text-secondary mx-4'>
											{translations.settings.preModeration.descr}
										</p>
									</div>
									<div>
										<Form.Check
											inline
											className='checkbox-black mb-2'
											name='messagePicsModerationType'
											type='radio'
											label={translations.settings.parallelModeration.title}
											id='PARALLEL'
											value={MessagePicsModerationType.PARALLEL}
											required
											onChange={onChangeMessagePicsModerationHandler}
											checked={messagePicsModerationType === MessagePicsModerationType.PARALLEL}
										/>
										<p className='text-secondary mx-4'>
											{translations.settings.parallelModeration.descr}
										</p>
									</div>
								</Form.Group>
							</div>
						</Tab>
						{watchersAppInstance && categories && (
							<Tab
								eventKey='tolokaUsage'
								title={translations.settings.moderationThresholds}
								tabClassName='text-secondary'>
								<div className='block w-50'>
									<Row className='mb-2 align-items-center'>
										<Col md={4}>
											<Form.Label>
												<b className='text-secondary'>Category</b>
											</Form.Label>
										</Col>
										<Col md={2} className='d-flex align-items-center'>
											<Form.Label>
												<b className='text-secondary'>Threshold (%)</b>
											</Form.Label>
										</Col>
									</Row>
									{categories.map((item: {category: string; value: number}, index: number) => {
										return (
											<Row key={index} className='mb-2 align-items-center'>
												<Col md={4}>
													<Form.Label>
														<b>{item.category}</b>
													</Form.Label>
												</Col>

												<Col md={2} className='d-flex align-items-center'>
													{/* <span
														style={{
															width: `20px`,
															display: 'block',
															flexShrink: '0',
														}}>
														{item.value === 100 ? '1.' : '0.'}&nbsp;
													</span> */}
													<Form.Control
														className='col-2'
														name={item.category}
														// placeholder={translations.gambles.gambleIntervalMS.title}
														value={item.value}
														onChange={onChangeValueHandler}
														onBlur={onBlurHandler}
														required
													/>
												</Col>
											</Row>
										);
									})}
								</div>
							</Tab>
						)}
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Automoderation);

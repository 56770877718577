import {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import Sidebar from 'components/sidebar/Sidebar';
import {Switch, Route, Redirect} from 'react-router-dom';

import {Container} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';

import {Scrollbars} from 'react-custom-scrollbars-2';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

import Rooms from 'pages/main/Rooms';
import Room from 'pages/main/Room';
import classNames from 'classnames';
import PreCreated from './main/PreCreated';
import Banned from './main/Banned';
import BannedHistory from './main/BannedHistory';
import Reports from './main/Reports';
import ReportsArchive from './main/ReportsArchive';
import Moders from './main/Moders';
import WordList from './main/WordList';
import Admin from './main/Admin';
import LastMessages from './main/LastMessages';
import UserMessages from './main/UserMessages';
import HighlightsList from './main/HighlightsList';
import CreateHighlight from './main/components/highlights/CreateHighlight';
import AutoBanned from './main/AutoBanned';
import Polls from './main/Polls';
import Feedback from './main/Feedback';
import DefaultLists from './main/DefaultLists';
import CreatePoll from './main/components/polls/CreatePoll';
import Language from './main/Language';
import Slowmode from './main/Slowmode';
import Chatbot from './main/Chatbot';
import Appearance from './main/Appearance';
import Automoderation from './main/Automoderation';
import FlaggedMessage from './main/FlaggedMessage';
import Email from './main/Email';
import Amplitude from './main/Amplitude';
import FunctionalSettings from './main/FunctionalSettings';
import ProfileSettings from './main/ProfileSettings';
import Export from './main/Export';
import Projects from './main/Projects';
import ProjectList from './main/ProjectList';
import Welcome from './main/Welcome';
import CreateWelcome from './main/components/welcome/CreateWelcome';
import S3Provider from './main/S3Provider';
import Analytics from './main/Analytics';
import APIKey from './main/APIKey';
import PollsResult from './main/PollsResult';
import BearerTokens from './main/BearerTokens';
import Semantic from './main/Semantic';
import CreateSemantic from './main/components/semantic/CreateSemantic';
import Autoposting from './main/Autoposting';
import Webhooks from './main/Webhooks';
import LiveStreams from './main/LiveStreams';
import Offers from './main/Offers';
import OfferCreate from './main/components/offers/OfferCreate';
import Gamble from './main/Gamble';
import Badges from './main/components/badges/Badges';
import CreateBadge from './main/components/badges/CreateBadge';
import Images from './main/Images';
import Stickers from './main/components/settings/appearence/stickers/Stickers';
import Changelog from './main/Changelog';
import ChangelogCreate from './main/components/changelog/ChangelogCreate';

const Main: FunctionComponent = function Main() {
	const [isMenuOpen, setIsMenuOpen] = useState(true);
	const translations = useL10n();

	const {appTheme} = useLocalObservable(() => appService);
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const menuOpenStyles = classNames('layout__content', {
		'layout__content--wide': !isMenuOpen,
	});

	const mainStyles = classNames('layout', {
		'bg-light': appTheme === Theme.LIGHT,
		'bg-dark': appTheme === Theme.DARK,
	});

	return (
		<section className={mainStyles}>
			<Sidebar toggleMenu={toggleMenu} />
			<div className={menuOpenStyles}>
				<Scrollbars autoHeight autoHeightMax='100vh' autoHeightMin={0}>
					<Container fluid>
						<Switch>
							<Route exact path='/' render={() => <Redirect to='/last-messages' />} />
							<Route exact path='/rooms'>
								<Rooms />
							</Route>
							<Route path='/room/:slug'>
								<Room />
							</Route>
							<Route path='/precreated'>
								<PreCreated />
							</Route>
							<Route path='/banned'>
								<Banned />
							</Route>
							<Route path='/bans-history'>
								<BannedHistory />
							</Route>
							<Route path='/reports'>
								<Reports />
							</Route>
							<Route path='/reports-archive'>
								<ReportsArchive />
							</Route>
							<Route path='/last-messages'>
								<LastMessages />
							</Route>
							<Route path='/moders'>
								<Moders />
							</Route>
							<Route path='/blacklist'>
								<WordList type='black' title={translations.sidebar.blackList.title} />
							</Route>
							<Route path='/whitelist'>
								<WordList type='white' title={translations.sidebar.whiteList.title} />
							</Route>
							<Route path='/phraselist'>
								<WordList type='phrase' title={translations.sidebar.blackPhraseList.title} />
							</Route>
							<Route path='/sitelist'>
								<WordList type='site' title={translations.sidebar.siteList.title} />
							</Route>
							<Route path='/admin'>
								<Admin />
							</Route>
							<Route path='/user-messages/:slug'>
								<UserMessages />
							</Route>
							<Route exact path='/highlights'>
								<HighlightsList />
							</Route>
							<Route path='/highlights/create'>
								<CreateHighlight />
							</Route>
							<Route path='/highlights/edit/:slug'>
								<CreateHighlight />
							</Route>
							<Route path='/auto-banned'>
								<AutoBanned />
							</Route>
							<Route exact path='/polls'>
								<Polls />
							</Route>
							<Route path='/polls/create'>
								<CreatePoll />
							</Route>
							<Route path='/polls/edit/:slug'>
								<CreatePoll />
							</Route>
							<Route path='/feedback'>
								<Feedback />
							</Route>
							<Route path='/default-lists'>
								<DefaultLists />
							</Route>
							<Route path='/language'>
								<Language />
							</Route>
							<Route path='/slowmode'>
								<Slowmode />
							</Route>
							<Route path='/chatbot'>
								<Chatbot />
							</Route>
							<Route path='/appearance'>
								<Appearance />
							</Route>
							<Route path='/badges'>
								<Badges />
							</Route>
							<Route path='/automoderation'>
								<Automoderation />
							</Route>
							<Route path='/flaggedMessage'>
								<FlaggedMessage />
							</Route>
							<Route path='/email'>
								<Email />
							</Route>
							<Route path='/amplitude'>
								<Amplitude />
							</Route>
							<Route path='/functionalSettings'>
								<FunctionalSettings />
							</Route>
							<Route path='/profileSettings'>
								<ProfileSettings />
							</Route>
							<Route path='/export'>
								<Export />
							</Route>
							<Route path='/projects'>
								<Projects />
							</Route>
							<Route path='/projectList'>
								<ProjectList />
							</Route>
							<Route exact path='/welcome'>
								<Welcome />
							</Route>
							<Route path='/welcome/create'>
								<CreateWelcome />
							</Route>
							<Route path='/s3provider'>
								<S3Provider />
							</Route>
							<Route path='/analytics'>
								<Analytics />
							</Route>
							<Route path='/apikey'>
								<APIKey />
							</Route>
							<Route path='/polls/result/:slug'>
								<PollsResult />
							</Route>
							<Route path='/bearer'>
								<BearerTokens />
							</Route>
							<Route exact path='/semantic'>
								<Semantic />
							</Route>
							<Route path='/semantic/create'>
								<CreateSemantic />
							</Route>
							<Route path='/semantic/:slug'>
								<CreateSemantic />
							</Route>
							<Route path='/autoposting'>
								<Autoposting />
							</Route>
							<Route path='/webhooks'>
								<Webhooks />
							</Route>
							<Route path='/liveStreams'>
								<LiveStreams />
							</Route>
							<Route exact path='/offers'>
								<Offers />
							</Route>
							<Route path='/offers/create'>
								<OfferCreate />
							</Route>
							<Route path='/offers/edit/:slug'>
								<OfferCreate />
							</Route>
							<Route path='/gambles'>
								<Gamble />
							</Route>
							<Route path='/create-badge'>
								<CreateBadge />
							</Route>
							<Route path='/edit-badge/:slug'>
								<CreateBadge />
							</Route>
							<Route path='/images'>
								<Images />
							</Route>
							<Route path='/stickers/:slug'>
								<Stickers />
							</Route>
							<Route path='/stickers'>
								<Stickers />
							</Route>
							<Route exact path='/changelog'>
								<Changelog />
							</Route>
							<Route path='/changelog/create'>
								<ChangelogCreate />
							</Route>
							<Route path='/changelog/:slug'>
								<ChangelogCreate />
							</Route>
						</Switch>
					</Container>
				</Scrollbars>
			</div>
		</section>
	);
};
export default observer(Main);

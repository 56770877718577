import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import StickersService from 'services/api/StickersService';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import useL10n from 'l10n/useL10n';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const translations = useL10n();

	const getStickerPacks = async () => {
		const response = await StickersService.getStickerPacks(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const getStickerPackById = async (id: number) => {
		const response = await StickersService.getStickerPackById(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const addStickerPack = async (name: string) => {
		const response = await StickersService.addStickerPack(accessToken, name);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const updateStickerPack = async (value: {id: number; name: string; isEnabled?: boolean}) => {
		const response = await StickersService.updateStickerPack(accessToken, value);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return response.data;
		}
		return false;
	};

	const deleteStickerPack = async (id: number) => {
		const response = await StickersService.deleteStickerPack(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,

				variant: 'success',
			});
			return response.data;
		}
		return false;
	};

	const addStickers = async (files: {file: File; emojis?: string[]}[], stikerPackId: number) => {
		const response = await StickersService.addSticker(accessToken, files, stikerPackId);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.stickers.added,
				variant: 'success',
			});

			// await getStickers();
			return response.data;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const updateSticker = async (value: {id: number; emojis: any}) => {
		const response = await StickersService.updateSticker(accessToken, value);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	return {
		getStickerPacks,
		getStickerPackById,
		addStickerPack,
		updateStickerPack,
		deleteStickerPack,
		addStickers,
		updateSticker,
	};
};

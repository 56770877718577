import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';

import {Container, Image, Form, Badge, Button, Row, Col} from 'react-bootstrap';
import {BsChevronDown} from 'react-icons/bs';

import RoomService from 'services/api/RoomService';

import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Talker} from 'models/room';
import UserRole from 'models/enums/UserRole.enum';

import useL10n from 'l10n/useL10n';
import ModerService from 'services/api/ModerService';
import messagesServices from 'store/messagesServices';
import appService from 'store/appService';
import {Project} from 'models/enums/Project.enum';
import modalServices from 'store/modalServices';

interface IRoomTalkerElem {
	talker: Talker;
	index: number;
	externalRoomId?: string | null;
}

const RoomTalkerElem: FunctionComponent<IRoomTalkerElem> = function RoomTalkerElem({
	talker,
	index,
	externalRoomId,
}) {
	const {projectId} = useLocalObservable(() => appService);
	const {accessToken, adminData, setAdminData} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {currentRoomId} = useLocalObservable(() => roomServices);
	const {updateTalkerByTalkerId} = useLocalObservable(() => messagesServices);
	const {showBanModal, hideRoomTalkersModal} = useLocalObservable(() => modalServices);
	const [switchSpeakerState, setSwitchSpeakerState] = useState<boolean>(false);
	const [switchModerState, setSwitchModerState] = useState<boolean>(false);

	const [invitedGuest, setInvitedGuest] = useState<boolean>(false);
	const [avatar, setAvatar] = useState('');
	const [showMore, setShowMore] = useState(false);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const translations = useL10n();

	const project = projectId.split('-')[0];

	const setUserInvitedGuest = async (value: boolean) => {
		if (currentRoomId) {
			const response = await RoomService.setUserInvitedGuest(
				accessToken,
				currentRoomId,
				talker.user.id,
				value
			);
			if (response.status === ResponseStatus.ERROR) {
				addToast({
					title: translations.toasts.error,
					text: translations.toasts.error,
					variant: 'warning',
				});
			}
		}
	};

	const setTalkerRole = async (role: UserRole) => {
		if (currentRoomId) {
			const response = await RoomService.setTalkerRole(accessToken, currentRoomId, talker.id, role);
			if (response.status === ResponseStatus.SUCCESS) {
				addToast({
					title: translations.toasts.user(talker.user.name),
					text: `${
						role === 'SPEAKER'
							? translations.toasts.addToSpeakers
							: translations.toasts.removeFromSpeakers
					}`,
				});
			}
			if (response.status === ResponseStatus.ERROR) {
				addToast({
					title: translations.toasts.error,
					text: translations.toasts.error,
					variant: 'warning',
				});
			}
		}
	};

	const toggleTalkerModer = async (value: boolean) => {
		if (!externalRoomId) return;
		const moderData = {
			talkerId: talker.id,
			externalRoomId,
			value,
		};
		const response = await ModerService.toggleTalkerModer(moderData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			const updatedTalker = {...talker, isModer: value};
			updateTalkerByTalkerId(updatedTalker);
			// eslint-disable-next-line no-use-before-define
			addToast({
				title: translations.toasts.user(talker.user.name),
				text: switchModerState
					? translations.toasts.removeFromModers
					: translations.toasts.addToModers,
			});
		} else if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const onSwitchSpeaker = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setSwitchSpeakerState(!switchSpeakerState);
		setTalkerRole(checked ? UserRole.SPEAKER : UserRole.GUEST);
	};

	const onSwitchGuest = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setInvitedGuest(!invitedGuest);
		setUserInvitedGuest(checked);
	};

	const onSwitchModer = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setSwitchModerState(!switchModerState);
		toggleTalkerModer(checked);
	};

	const setUserAvatar = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const pic = event.target.files && event.target.files[0];
		if (pic) {
			const response = await RoomService.setUserAvatar(accessToken, pic, talker.user.id);
			if (inputFileRef.current) inputFileRef.current.value = '';
			if (response.status === ResponseStatus.ERROR) {
				addToast({
					title: translations.toasts.error,
					text: translations.toasts.error,
					variant: 'warning',
				});
				return;
			}

			setAvatar(URL.createObjectURL(pic));
			if (adminData.id === talker?.user?.id) setAdminData(response.data);
		}
	};

	const openBanModal = (type: string) => {
		hideRoomTalkersModal();
		externalRoomId &&
			showBanModal({
				type,
				targetName: talker.user?.name,
				targetId: type === 'banUser' ? talker.user?.id : talker.id,
				externalRoomId,
			});
	};

	useEffect(() => {
		if (project === Project.LIGA) setInvitedGuest(talker.user.isInvitedGuest);
		setSwitchSpeakerState(talker.role === 'SPEAKER');
		setSwitchModerState(talker.isModer);
		setAvatar(talker.user.pic);
	}, [talker]);

	return (
		<Container fluid key={index} className='modal__talker-item'>
			<Row>
				<Col md={4} className='d-flex align-items-center'>
					<label className='modal__talker-pic' htmlFor={`avatar-${index}`}>
						<Image roundedCircle src={avatar} className='image-preview modal__talker-image' />
						<input
							id={`avatar-${index}`}
							className='modal__talker-inputfile'
							type='file'
							ref={inputFileRef}
							onChange={setUserAvatar}
						/>
					</label>

					<p className='modal__talker-name'>{talker.user.name} &nbsp;</p>
					{(talker.user.isModer || talker.isModer) && (
						<Badge pill bg='secondary'>
							admin
						</Badge>
					)}
					{talker.user?.bans?.length > 0 && (
						<Badge pill bg='danger'>
							{translations.badges.userBanned}
						</Badge>
					)}
				</Col>

				<Col md={8} className='d-flex align-items-center p-0'>
					<Row className='w-100 p-0'>
						<Col md={10} className='ms-auto d-flex align-items-center justify-content-end p-0'>
							<Form.Check
								type='switch'
								id={`moderToggle-${index}`}
								label={translations.moderator}
								onChange={onSwitchModer}
								checked={switchModerState}
							/>
							&nbsp;&nbsp;&nbsp;
							<div className='mx-4'>
								<Form.Check
									type='switch'
									id={`roleToggle-${index}`}
									label={translations.speaker}
									onChange={onSwitchSpeaker}
									checked={switchSpeakerState}
								/>
							</div>
							{project === Project.LIGA && (
								<div className='ms-2'>
									<Form.Check
										type='switch'
										id={`invitedGuest-${index}`}
										label={translations.invitedGuest}
										onChange={onSwitchGuest}
										checked={invitedGuest}
									/>
								</div>
							)}
						</Col>
						<Col md={2} className='d-flex justify-content-end p-0'>
							{!talker.user.isModer && !talker.isModer && (
								<Button onClick={() => setShowMore(!showMore)} className='ms-auto' variant='light'>
									<BsChevronDown />
								</Button>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
			{showMore && (
				<div className='m-4'>
					<div className='d-flex'>
						<Button
							variant='danger'
							size='sm'
							onClick={() => openBanModal('banTalker')}
							disabled={talker.bans?.length > 0}>
							{translations.submenu.banInRoom}
						</Button>
						&nbsp;&nbsp;
						<Button
							variant='danger'
							size='sm'
							onClick={() => openBanModal('banUser')}
							disabled={talker.user?.bans?.length > 0}>
							{translations.submenu.ban}
						</Button>
					</div>
				</div>
			)}
		</Container>
	);
};

export default observer(RoomTalkerElem);

import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import {filter} from 'models/filter';

export default class FeedbackService {
	static getFeedback = async (
		token: UUID,
		limit: number,
		offset: number,
		order: string,
		searchFilter?: filter | null
	) => {
		try {
			let apiUrl;
			if (searchFilter) {
				let filtersString = '';

				filtersString += `&${searchFilter.key}=${searchFilter.prefix}${
					searchFilter.prefix ? '_' : ''
				}${searchFilter.value}`;

				apiUrl = apiPath.getFeedback(limit, offset, order, filtersString);
			} else apiUrl = apiPath.getFeedback(limit, offset, order);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

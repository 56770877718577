import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {ExportTypes} from 'models/enums/ExportTypes.enum';

export default class ExportService {
	static getDump = async (token: UUID, limit: number, offset: number) => {
		try {
			const apiUrl = `${apiPath.exportDump}?limit=${limit}&offset=${offset}`;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static exportUserActivity = async (
		token: UUID,
		body: {from: string; to: string},
		type: ExportTypes
	) => {
		try {
			const apiUrl =
				type === ExportTypes.USER_ACTIVITY ? apiPath.exportUserActivity : apiPath.exportMessage;
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

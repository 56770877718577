/* eslint-disable max-lines */
import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';

import {Container, Row, Col, Breadcrumb, FloatingLabel, Form, Image, Button} from 'react-bootstrap';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Chatbot as ChatbotType} from 'models/settings';
import PageTitle from 'components/pageTitle/PageTitle';
import {statisChatGPTModels} from 'constants/constants';

const Chatbot: FunctionComponent = function Chatbot() {
	const translations = useL10n();
	const {getChatbotSettings, patchChatbotSettings, getChatbotModels} = useSettings();
	const [validated, setValidated] = useState(false);
	const [image, setImage] = useState('');
	const [isSaved, setIsSaved] = useState(true);
	const [currentChatbot, setCurrentChatbot] = useState<ChatbotType>();
	const [models, setModels] = useState<any[]>([]);

	const [chatbot, setChatbot] = useState<ChatbotType>({
		apiKey: '',
		name: '',
		model: '',
		pic: '',
		isActive: true,
		temperature: 0,
		max_tokens: 0,
		top_p: 0,
		frequency_penalty: 0,
		presence_penalty: 0,
	});

	const watchersAppInstance = process.env.REACT_APP_INSTANCE === 'watchers';

	const getChatbotSettingsByService = async () => {
		const response = await getChatbotSettings();
		if (response.status === ResponseStatus.SUCCESS) {
			setChatbot(response.data);
			setCurrentChatbot(response.data);
			setImage(response.data.pic);
		}
	};

	const getChatbotModelsByService = async (apiKey: string) => {
		const response = await getChatbotModels(apiKey);
		if (response.status === ResponseStatus.SUCCESS) {
			setModels(response.data);
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else {
			setIsSaved(false);
			await patchChatbotSettings(chatbot);
			getChatbotSettingsByService();
			setIsSaved(true);
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value: any = event.target.value.replace(/[^\d.]/g, '');

		value = value.replace(/^([^.]*\.)(.*)$/, (a: any, b: any, c: any) => {
			return b + c.replace(/\./g, '');
		});

		if (event.target.name !== 'max_tokens' && +value > 1) value = '1';
		if (event.target.name === 'max_tokens') value = +value;

		setChatbot({
			...chatbot,
			[event.target.name]: value,
		});
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setChatbot({
			...chatbot,
			[event.target.name]: value.replace(/\.$/, ''),
		});
	};

	const onNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.trim();
		setChatbot({
			...chatbot,
			name: value,
		});
	};

	const onApiKeyChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.trim();
		setChatbot({
			...chatbot,
			apiKey: value,
		});
	};

	const onSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setChatbot({
			...chatbot,
			model: value,
		});
	};

	const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setChatbot({
					...chatbot,
					pic: file,
				});
				setImage(URL.createObjectURL(file));
			}
		}
	};

	useEffect(() => {
		if (chatbot.apiKey) {
			watchersAppInstance
				? getChatbotModelsByService(chatbot.apiKey)
				: setModels(statisChatGPTModels);
		}
	}, [chatbot]);

	useEffect(() => {
		getChatbotSettingsByService();
	}, []);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.chatGPT.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.chatGPT.title}
						text={translations.sidebar.chatGPT.descr}
					/>

					<div className='block w-50 mb-5'>
						<p className='h5 mb-5'>{translations.settings.chatbot}</p>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<Form.Check
								onChange={() => setChatbot({...chatbot, isActive: !chatbot.isActive})}
								className='mb-3'
								type='switch'
								id='slowmodeToggle'
								label={translations.settings.enable}
								checked={chatbot.isActive}
								disabled={process.env.REACT_APP_INSTANCE === 'social-solutions'}
							/>

							<FloatingLabel
								controlId='openai.api_key'
								label='openai.api_key'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									name='apiKey'
									placeholder='openai.api_key'
									value={chatbot.apiKey}
									onChange={onApiKeyChangeHandler}
									maxLength={250}
									type='password'
									required={chatbot.isActive}
								/>
							</FloatingLabel>

							{currentChatbot?.apiKey && (
								<div>
									<Form.Group className='mb-3' controlId='adminPic'>
										{image && <Image roundedCircle src={image} className='image-preview' />}

										<Form.Control
											type='file'
											accept='.jpg, .jpeg, .png, .gif, .svg'
											hidden
											onChange={readFile}
										/>
										<Form.Label className='btn btn-outline-secondary btn-sm m-2'>
											{translations.btns.addAvatar}
										</Form.Label>
									</Form.Group>

									<FloatingLabel
										controlId='name'
										label='@name'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='name'
											placeholder='@name'
											value={chatbot.name}
											onChange={onNameChangeHandler}
											onBlur={onBlurHandler}
											required
										/>
									</FloatingLabel>

									<FloatingLabel
										controlId='model'
										label='model'
										className='w-100 text-secondary mb-3'>
										<Form.Select onChange={onSelect} value={chatbot.model}>
											{models.map(model => (
												<option key={model} value={model}>
													{model}
												</option>
											))}
										</Form.Select>
									</FloatingLabel>

									<FloatingLabel
										controlId='temperature'
										label='temperature'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='temperature'
											placeholder='temperature'
											value={chatbot.temperature}
											onChange={onChangeHandler}
											onBlur={onBlurHandler}
											required
											maxLength={8}
										/>
									</FloatingLabel>

									<FloatingLabel
										controlId='max_tokens'
										label='max_tokens'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='max_tokens'
											placeholder='max_tokens'
											value={chatbot.max_tokens}
											onChange={onChangeHandler}
											onBlur={onBlurHandler}
											required
										/>
									</FloatingLabel>

									<FloatingLabel
										controlId='top_p'
										label='top_p'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='top_p'
											placeholder='top_p'
											value={chatbot.top_p}
											onChange={onChangeHandler}
											onBlur={onBlurHandler}
											required
											maxLength={8}
										/>
									</FloatingLabel>

									<FloatingLabel
										controlId='frequency_penalty'
										label='frequency_penalty'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='frequency_penalty'
											placeholder='frequency_penalty'
											value={chatbot.frequency_penalty}
											onChange={onChangeHandler}
											onBlur={onBlurHandler}
											required
											maxLength={8}
										/>
									</FloatingLabel>

									<FloatingLabel
										controlId='presence_penalty'
										label='presence_penalty'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='presence_penalty'
											placeholder='presence_penalty'
											value={chatbot.presence_penalty}
											onChange={onChangeHandler}
											onBlur={onBlurHandler}
											required
											maxLength={8}
										/>
									</FloatingLabel>
								</div>
							)}

							<Button type='submit' disabled={!isSaved}>
								{translations.btns.save}
							</Button>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Chatbot);

import {apiPath} from 'constants/api';
import axios from 'axios';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Highlight} from 'models/highlights';

export default class HighlightsService {
	static getHighlights = async (token: UUID, limit?: number | string, offset?: number) => {
		try {
			let apiUrl = apiPath.getHighlights;
			if (typeof offset !== 'undefined') {
				apiUrl = `${apiUrl}?limit=${limit || 100}&offset=${offset}`;
			} else apiUrl = `${apiUrl}?limit=${limit}`;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static createHighlight = async (token: UUID, highlight: Highlight) => {
		try {
			const formData = new FormData();
			highlight.title && formData.append('title', `${highlight.title}`);
			highlight.text && formData.append('text', `${highlight.text}`);
			formData.append('link', `${highlight.link}`);
			formData.append('openType', `${highlight.openType}`);
			highlight.template !== 11 &&
				highlight.linkText &&
				formData.append('linkText', `${highlight.linkText}`);

			formData.append('template', `${highlight.template}`);
			highlight.externalRoomIds &&
				formData.append(
					'externalRoomIds',
					Array.isArray(highlight.externalRoomIds)
						? JSON.stringify(highlight.externalRoomIds)
						: highlight.externalRoomIds
				);

			highlight.template === 10 &&
				formData.append('templateData', JSON.stringify(highlight.templateData));

			if (highlight.picFile) formData.append('pic', highlight.picFile, highlight.picFile.name);

			highlight.sendTime !== 'NOW' && formData.append('sendTime', `${highlight.sendTime}`);

			if (highlight.time && highlight.sendTime) {
				const sendTime = highlight.sendTime === 'NOW' ? new Date() : new Date(highlight.sendTime);
				let hours = 0;
				let minutes = 0;
				let seconds = 0;
				if (highlight.time.hours) hours = +highlight.time.hours * 60 * 60 * 1000;
				if (highlight.time.minutes) minutes = +highlight.time.minutes * 60 * 1000;
				if (highlight.time.seconds) seconds = +highlight.time.seconds * 1000;

				const deleteTime = new Date(sendTime.getTime() + hours + minutes + seconds).toISOString();

				formData.append('deleteTime', `${deleteTime}`);
			}

			highlight.deleteTime && formData.append('deleteTime', `${highlight.deleteTime}`);

			const apiUrl = apiPath.getHighlights;
			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response?.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};

	static getHighlight = async (token: UUID, highlightId: number) => {
		try {
			const apiUrl = apiPath.getHighlight(highlightId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateHighlight = async (token: UUID, highlightId: number, highlight: Highlight) => {
		try {
			const formData = new FormData();
			formData.append('title', `${highlight.title}`);
			formData.append('text', `${highlight.text}`);
			formData.append('link', highlight.link ? `${highlight.link}` : '');
			if (highlight.openType) formData.append('openType', highlight.openType);
			highlight.template !== 11 &&
				highlight.link &&
				highlight.linkText &&
				formData.append('linkText', `${highlight.linkText}`);

			formData.append('template', `${highlight.template}`);

			formData.append(
				'templateData',
				highlight.template === 10 ? JSON.stringify(highlight.templateData) : 'null'
			);

			if (highlight.picFile) formData.append('pic', highlight.picFile, highlight.picFile.name);

			highlight.status !== 'SENT' &&
				highlight.sendTime &&
				formData.append('sendTime', `${highlight.sendTime}`);

			if (highlight.time && highlight.sendTime) {
				const sendTime = highlight.sendTime === 'NOW' ? new Date() : new Date(highlight.sendTime);
				let hours = 0;
				let minutes = 0;
				let seconds = 0;
				if (highlight.time.hours) hours = +highlight.time.hours * 60 * 60 * 1000;
				if (highlight.time.minutes) minutes = +highlight.time.minutes * 60 * 1000;
				if (highlight.time.seconds) seconds = +highlight.time.seconds * 1000;

				const deleteTime = new Date(sendTime.getTime() + hours + minutes + seconds).toISOString();

				formData.append('deleteTime', `${deleteTime}`);
			} else formData.append('deleteTime', `${highlight.deleteTime}`);

			const apiUrl = apiPath.getHighlight(highlightId);
			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteHighlight = async (token: UUID, highlightId: number) => {
		try {
			const apiUrl = apiPath.getHighlight(highlightId);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getHighlightPics = async (token: UUID) => {
		try {
			const apiUrl = apiPath.getHighlightPics;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

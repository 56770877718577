import {useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import roomServices from 'store/roomServices';
import RoomService from 'services/api/RoomService';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {setPinnedMessages} = useLocalObservable(() => roomServices);

	const pinMessage = async (externalRoomId: string, messageId: number | null) => {
		await RoomService.pinMessage(accessToken, externalRoomId, messageId);
	};

	const unpinMessage = async (externalRoomId: string, messageId: number | null) => {
		await RoomService.unpinMessage(accessToken, externalRoomId, messageId);
	};

	const getPinnedMessage = async (externalRoomId: string) => {
		const response = await RoomService.getPinnedMessage(accessToken, externalRoomId);

		if (response.status === ResponseStatus.SUCCESS) {
			setPinnedMessages(response.data);
		}
	};

	return {
		pinMessage,
		unpinMessage,
		getPinnedMessage,
	};
};

import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class SemanticAnalysisService {
	static getPrompts = async (token: UUID) => {
		try {
			const apiUrl = apiPath.prompts;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPrompt = async (token: UUID, id: number) => {
		try {
			const apiUrl = apiPath.prompt(id);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deletePrompt = async (token: UUID, id: number) => {
		try {
			const apiUrl = apiPath.prompt(id);

			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPromptHistory = async (token: UUID) => {
		try {
			const apiUrl = apiPath.promptHistory;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addPromptHistory = async (token: UUID, promptId: number) => {
		try {
			const apiUrl = apiPath.promptHistory;
			const body = {
				promptId,
			};
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deletePromptHistory = async (token: UUID, id: number) => {
		try {
			const apiUrl = apiPath.deletePromptHistory(id);

			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getModels = async (token: UUID, key: string) => {
		try {
			const apiUrl = apiPath.openaiModels(key);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getOpenaiKey = async (token: UUID) => {
		try {
			const apiUrl = apiPath.openaiApiKey;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addPrompt = async (token: UUID, body: any) => {
		try {
			const apiUrl = apiPath.prompts;

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static endPrompt = async (token: UUID, body: any) => {
		try {
			const apiUrl = apiPath.endPrompt;

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message);
		}
	};

	static promptDraft = async (token: UUID, body: any) => {
		try {
			const apiUrl = apiPath.promptDraft;

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message);
		}
	};
}

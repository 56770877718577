import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';
import AuthService from 'services/api/AdminProxyService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {useHistory} from 'react-router-dom';
// bootstrap components
import {Container, FloatingLabel, Button, Form, Col} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import UserRole from 'models/enums/UserRole.enum';
import appService from 'store/appService';
import axios from 'axios';
import SelectProject from 'components/selectProject/selectProject';

const Login: FunctionComponent = function Login() {
	const history = useHistory();
	const translations = useL10n();

	const [loginData, setLoginData] = useState({
		name: '',
		password: '',
	});
	const [responseStatus, setResponseStatus] = useState(true);
	const [validated, setValidated] = useState(false);

	const {projectId, setProjectId} = useLocalObservable(() => appService);
	const {setAccessToken, setIsAdmin, projectUsers, setProjectUsers, setIsSuperAdmin, setIsCreator} =
		useLocalObservable(() => userServices);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		const valueTrim = value.trim();
		setLoginData({
			...loginData,
			[event.target.name]: valueTrim,
		});
	};

	const getUserBySelectedProject = (project: string) => {
		return projectUsers.find((el: any) => el.projectId === project);
	};

	const setAuth = (token: string) => {
		setAccessToken(token);
		localStorage.setItem('accessToken', token);
	};

	const setSuperAdmin = () => {
		setIsSuperAdmin(true);
		localStorage.setItem('isSuperAdmin', 'true');
	};

	const setCreator = () => {
		setIsCreator(true);
		localStorage.setItem('isCreator', 'true');
	};

	const setAllUsers = (response: any) => {
		setProjectUsers(response.data.projectUsers);
		localStorage.setItem('projectUsers', JSON.stringify(response.data.projectUsers));
	};

	const setUser = (project: string) => {
		const user: any = getUserBySelectedProject(project);
		localStorage.setItem('user', JSON.stringify(user));
		setIsAdmin(user?.role === UserRole.ADMIN);
	};

	const setProject = (response: any) => {
		const project = response.data.projectUsers[0].projectId;
		setProjectId(project);
		localStorage.setItem('projectId', project);
	};

	const setUserData = (response: any) => {
		setAuth(response.data.access_token);
		response.data.isSuperAdmin && setSuperAdmin();
		response.data.isCreator && setCreator();

		setAllUsers(response);
		setProject(response);
	};

	const login = async () => {
		const response = await AuthService.login(loginData);
		if (response.status === ResponseStatus.ERROR) {
			setResponseStatus(false);
			setValidated(false);
		}
		if (response.status === ResponseStatus.SUCCESS) {
			setUserData(response);
			axios.defaults.baseURL = process.env.REACT_APP_API_URL;
			if (response.data.projectUsers.length === 1) history.push('/last-messages');
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else login();
	};

	const onLoginClickHandler = () => {
		history.push('/last-messages');
	};

	useEffect(() => {
		if (projectId && projectUsers.length) {
			setUser(projectId);
			axios.defaults.headers.common.project = projectId;
		}
	}, [projectId, projectUsers]);

	return (
		<section className='w-100 d-flex bg-dark'>
			<Container as={Col} md='4' className='m-auto'>
				{!projectUsers.length ? (
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<p className='h5 text-primary mb-4 text-center'>{translations.auth.title}</p>

						<FloatingLabel
							controlId='floatingInput'
							label={translations.auth.login}
							className='mb-3'>
							<Form.Control
								name='name'
								placeholder='name@example.com'
								required
								value={loginData.name}
								onChange={onChangeHandler}
							/>
						</FloatingLabel>

						<FloatingLabel
							className='mb-3'
							controlId='floatingPassword'
							label={translations.auth.password}>
							<Form.Control
								type='password'
								name='password'
								placeholder='Password'
								required
								value={loginData.password}
								onChange={onChangeHandler}
							/>
							{!responseStatus && (
								<p className='text-danger mt-2 mb-2'>{translations.auth.passwordWarn}</p>
							)}
						</FloatingLabel>

						<Button type='submit' variant='primary' size='lg' className='w-100'>
							{translations.btns.login}
						</Button>
					</Form>
				) : (
					projectUsers.length > 1 && (
						<div>
							<div className='mb-3'>
								<SelectProject projectUsers={projectUsers} />
							</div>

							<Button
								onClick={() => onLoginClickHandler()}
								type='button'
								variant='primary'
								size='lg'
								className='w-100'>
								{translations.btns.login}
							</Button>
						</div>
					)
				)}
			</Container>
		</section>
	);
};

export default observer(Login);

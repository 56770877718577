import {apiPath} from 'constants/api';
import axios from 'axios';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Offer} from 'models/offer';
import {OfferType} from 'models/enums/OfferType.enum';

export default class OfferService {
	static getOffers = async (token: UUID, limit?: number | string, offset?: number) => {
		try {
			let apiUrl = apiPath.getOffers;
			if (typeof offset !== 'undefined') {
				apiUrl = `${apiUrl}?limit=${limit || 20}&offset=${offset}`;
			} else apiUrl = `${apiUrl}?limit=${limit}`;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static createOffer = async (token: UUID, offer: Offer) => {
		try {
			const formData = new FormData();
			offer.title && formData.append('title', `${offer.title}`);
			offer.text && formData.append('text', `${offer.text}`);
			formData.append('link', `${offer.link}`);
			if (offer.openType) formData.append('openType', offer.openType);
			offer.template !== 11 && offer.linkText && formData.append('linkText', `${offer.linkText}`);

			formData.append('template', `${offer.template}`);
			if (offer.type) formData.append('type', `${offer.type}`);

			if (offer.externalRoomIds && offer.type === OfferType.ROOM)
				formData.append(
					'externalRoomIds',
					Array.isArray(offer.externalRoomIds)
						? JSON.stringify(offer.externalRoomIds)
						: offer.externalRoomIds
				);

			if (offer.externalUserIds && offer.type === OfferType.USER)
				formData.append(
					'externalUserIds',
					Array.isArray(offer.externalUserIds)
						? JSON.stringify(offer.externalUserIds)
						: offer.externalUserIds
				);
			offer.template === 10 && formData.append('templateData', JSON.stringify(offer.templateData));

			if (offer.picFile) formData.append('pic', offer.picFile, offer.picFile.name);

			formData.append('showMultipleTimes', `${offer.showMultipleTimes}`);
			if (offer.showFrerquency) formData.append('showFrerquency', offer.showFrerquency);
			if (offer.showDelayMS) formData.append('showDelayMS', `${offer.showDelayMS}`);

			if (offer.startTime) formData.append('startTime', `${offer.startTime}`);
			if (offer.endTime) formData.append('endTime', `${offer.endTime}`);

			const apiUrl = apiPath.getOffers;
			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response?.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};

	static getOffer = async (token: UUID, offerId: number) => {
		try {
			const apiUrl = apiPath.offer(offerId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateOffer = async (token: UUID, offerId: number, offer: Offer) => {
		try {
			const formData = new FormData();
			formData.append('title', `${offer.title}`);
			formData.append('text', `${offer.text}`);
			formData.append('link', offer.link ? `${offer.link}` : '');
			if (offer.openType) formData.append('openType', offer.openType);
			offer.template !== 11 &&
				offer.link &&
				offer.linkText &&
				formData.append('linkText', `${offer.linkText}`);

			formData.append('template', `${offer.template}`);

			formData.append(
				'templateData',
				offer.template === 10 ? JSON.stringify(offer.templateData) : 'null'
			);

			if (offer.picFile) formData.append('pic', offer.picFile, offer.picFile.name);
			if (offer.showMultipleTimes)
				formData.append('showMultipleTimes', `${offer.showMultipleTimes}`);
			if (offer.showFrerquency) formData.append('showFrerquency', offer.showFrerquency);
			if (offer.showDelayMS) formData.append('showDelayMS', `${offer.showDelayMS}`);
			if (offer.startTime) formData.append('startTime', `${offer.startTime}`);
			if (offer.endTime) formData.append('endTime', `${offer.endTime}`);

			const apiUrl = apiPath.offer(offerId);
			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteOffer = async (token: UUID, offerId: number) => {
		try {
			const apiUrl = apiPath.offer(offerId);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

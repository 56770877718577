/* eslint-disable no-param-reassign */
import axios from 'axios';
import {apiPath} from 'constants/api';
import {Settings} from 'models/autoposting';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class AutopostingService {
	static getSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.autopostingSettings;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postSettings = async (token: UUID, body: any) => {
		try {
			const apiUrl = apiPath.autopostingSettings;
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessageDefault = async (token: UUID) => {
		try {
			const apiUrl = apiPath.autopostingMessageDefault;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessage = async (token: UUID) => {
		try {
			const apiUrl = apiPath.autopostingMessage;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addMessageDefault = async (token: UUID, body: {lang: string; text: string}) => {
		try {
			const apiUrl = apiPath.autopostingMessageDefault;
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addMessage = async (token: UUID, body: {lang: string; text: string}) => {
		try {
			const apiUrl = apiPath.autopostingMessage;
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addMessageMultiple = async (token: UUID, messages: {lang: string; text: string}[]) => {
		try {
			const body = {
				messages,
			};
			const apiUrl = apiPath.autopostingMessageMultiple;
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessages = async (token: UUID, isDefault: boolean) => {
		try {
			const apiUrl = isDefault
				? apiPath.deleteAutopostingMessagesDefault
				: apiPath.deleteAutopostingMessages;
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessage = async (token: UUID, id: number, isDefault: boolean) => {
		try {
			const apiUrl = isDefault
				? apiPath.deleteAutopostingMessageDefault(id)
				: apiPath.deleteAutopostingMessage(id);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessageLang = async (token: UUID, lang: string, isDefault: boolean) => {
		try {
			const apiUrl = isDefault
				? apiPath.deleteMessageLangDefault(lang)
				: apiPath.deleteMessageLang(lang);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Spinner, Tabs, Tab, Button} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import EditorConvertToHTML from 'components/editorConvertToHTML/editorConvertToHTML';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import RulesService from 'services/api/RulesService';
import toastServices from 'store/toastServices';
import {Prompt, useHistory} from 'react-router-dom';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import settingsServices from 'store/settingsServices';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

type rules = {
	id?: number;
	lang: string;
	rulesHTML: string;
	policyHTML: string;
};

interface IChatRules {
	currentTab: string;
}

const ChatRules: FunctionComponent<IChatRules> = function ChatRules({currentTab}) {
	const translations = useL10n();
	const history = useHistory();
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {projectLanguage, settings} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [rules, setRules] = useState<rules[]>([]);
	const [currentRules, setCurrentRules] = useState<rules>({
		lang: 'ru',
		rulesHTML: '',
		policyHTML: '',
	});
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState('');
	const [key, setKey] = useState('');
	const [availableLangs, setAvailableLangs] = useState<string[]>([]);

	const preventSubmissionRef = useRef<boolean>(false);

	const getChatRules = async (lang?: string) => {
		setVisiblePreloader(true);
		const response = await RulesService.getRules(accessToken);
		setVisiblePreloader(false);

		if (response.status === ResponseStatus.SUCCESS) {
			setRules(response.data);

			if (lang) {
				const currentRulesData =
					response.data.find((el: rules) => el.lang === lang) ||
					response.data.find((el: rules) => el.lang === projectLanguage);
				if (currentRulesData) {
					setCurrentRules(currentRulesData);
					return;
				}
				setCurrentRules({
					lang,
					rulesHTML: '',
					policyHTML: '',
				});
				return;
			}

			const currentRulesData = response.data.find((el: rules) => el.lang === projectLanguage);
			if (currentRulesData) {
				setCurrentRules(currentRulesData);
				return;
			}

			setCurrentRules({
				lang: projectLanguage,
				rulesHTML: '',
				policyHTML: '',
			});
		}
	};

	const saveChatRules = async (value: {rulesHTML?: string; policyHTML?: string}) => {
		if (currentRules && currentRules.id) {
			setVisiblePreloader(true);
			const response = await RulesService.updateRules(accessToken, currentRules.id, {
				...value,
				lang: currentRules.lang,
			});
			setVisiblePreloader(false);
			let responseText = '';
			if (response.status === ResponseStatus.SUCCESS) {
				setUnsavedChanges('');
				await getChatRules(currentRules.lang);
				responseText = translations.toasts.rulesUpdated;
			} else if (response.status === ResponseStatus.ERROR) {
				responseText = translations.toasts.error;
			}
			addToast({
				title: translations.toasts.success,
				text: responseText,
				variant: response.status === ResponseStatus.SUCCESS ? 'success' : 'danger',
			});
		}
	};

	const addChatRules = async (value: {rulesHTML?: string; policyHTML?: string}) => {
		const response = await RulesService.addRules(accessToken, {...value, lang: key});
		let responseText = '';
		if (response.status === ResponseStatus.SUCCESS) {
			setUnsavedChanges('');
			await getChatRules(key);
			responseText = translations.toasts.rulesUpdated;
		} else if (response.status === ResponseStatus.ERROR) {
			responseText = translations.toasts.error;
		}
		addToast({
			title: translations.toasts.success,
			text: responseText,
			variant: response.status === ResponseStatus.SUCCESS ? 'success' : 'danger',
		});
	};

	const removeChatRules = async (id?: number) => {
		if (currentRules && id) {
			setVisiblePreloader(true);
			const response = await RulesService.deleteRules(accessToken, id);
			setVisiblePreloader(false);
			let responseText = '';
			if (response.status === ResponseStatus.SUCCESS) {
				setUnsavedChanges('');
				await getChatRules(currentRules.lang);
				responseText = translations.toasts.rulesUpdated;
			} else if (response.status === ResponseStatus.ERROR) {
				responseText = translations.toasts.error;
			}
			addToast({
				title: translations.toasts.success,
				text: responseText,
				variant: response.status === ResponseStatus.SUCCESS ? 'success' : 'danger',
			});
		}
	};

	const setUnsavedEditorChanges = (value: string) => {
		setUnsavedChanges(value);
		preventSubmissionRef.current = true;
	};

	const showUnsavedChangesAlert = (tab?: any) => {
		showAlert({
			title: translations.alerts.saveChanges,
			text: translations.alerts.unsavedChanges,
			buttons: [
				{
					text: translations.btns.dontSave,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
						tab && setKey(tab);
						setUnsavedChanges('');
						!tab && setConfirmedNavigation(true);
					},
				},
				{
					text: translations.btns.save,
					type: AlertBtnType.SUCCESS,
					onClick: async () => {
						currentRules.id
							? await saveChatRules({rulesHTML: unsavedChanges})
							: await addChatRules({rulesHTML: unsavedChanges});
						tab && setKey(tab);
						hideAlert();
						!tab && setConfirmedNavigation(true);
					},
				},
			],
		});
	};

	const handleBlockedNavigation = (nextLocation: any) => {
		setLastLocation(nextLocation.pathname);
		if (!confirmedNavigation) {
			showUnsavedChangesAlert();
			return false;
		}
		return true;
	};

	const tabClickHandler = (k: any) => {
		unsavedChanges ? showUnsavedChangesAlert(k) : setKey(k);
	};

	useEffect(() => {
		if (settings) settings.availableLangs && setAvailableLangs(settings.availableLangs?.split(','));
	}, [settings]);

	useEffect(() => {
		const langRules =
			rules.find((el: any) => el.lang === key) ||
			rules.find((el: any) => el.lang === projectLanguage);
		if (langRules) setCurrentRules(langRules);
		else
			setCurrentRules({
				lang: key,
				rulesHTML: '',
				policyHTML: '',
			});
	}, [key]);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation);
		}
	}, [confirmedNavigation, lastLocation]);

	useEffect(() => {
		currentTab === 'chatrules' && getChatRules();
		setKey(projectLanguage);
	}, [currentTab]);

	return (
		<div className='block'>
			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{!visiblePreloader && currentRules && (
				<Tabs
					activeKey={key}
					onSelect={(k: any) => tabClickHandler(k)}
					id='uncontrolled-tab-example'
					className='mb-3'>
					{availableLangs.length &&
						availableLangs.map((el: string) => {
							return (
								<Tab key={el} eventKey={el} title={el.toUpperCase()} tabClassName='text-secondary'>
									<div className='chat-policy mb-5 mt-5'>
										<div className='d-flex justify-content-end'>
											{currentRules.lang === el && (
												<Button
													variant='danger'
													size='sm'
													onClick={() => removeChatRules(currentRules.id)}>
													{translations.btns.remove}
												</Button>
											)}
										</div>

										<p className='h5 mb-3'>{translations.settings.chatRulesAccept}</p>
										<EditorConvertToHTML
											html={currentRules.policyHTML}
											unsavedChanges={unsavedChanges}
											save={
												currentRules.id && currentRules.lang === el ? saveChatRules : addChatRules
											}
											setUnsavedEditorChanges={setUnsavedEditorChanges}
											type='policyHTML'
											isSaveBtn
										/>
									</div>
									<p className='h5 mb-3'>{translations.settings.chatRules.title}</p>
									<p className='text-secondary mb-5'>{translations.settings.chatRules.descr}</p>
									<EditorConvertToHTML
										html={currentRules.rulesHTML}
										unsavedChanges={unsavedChanges}
										save={
											currentRules.id && currentRules.lang === el ? saveChatRules : addChatRules
										}
										setUnsavedEditorChanges={setUnsavedEditorChanges}
										type='rulesHTML'
										isSaveBtn
									/>
								</Tab>
							);
						})}
				</Tabs>
			)}

			{unsavedChanges && <Prompt when message={handleBlockedNavigation} />}
		</div>
	);
};

export default observer(ChatRules);

/* eslint-disable no-param-reassign */
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class PartnersCreatorService {
	static createProject = async (token: UUID, name: string) => {
		try {
			const {data, status} = await axios.post(
				apiPath.createProject,
				{name},
				{
					transformRequest: (requestData, headers) => {
						if (headers) delete headers.common;
						requestData = {
							name,
						};
						return JSON.stringify(requestData);
					},
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json; charset=utf-8',
						Accept: 'application/json',
					},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response.data.detail
					? error.response.data.detail.messages[0]
					: error.response.data.message
					? error.response.data.message
					: error.message
			);
		}
	};

	static getSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.projectSettings, {
				transformRequest: (requestData, headers) => {
					if (headers) delete headers.common;
				},
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static saveSettings = async (
		token: UUID,
		body: {dopplerAccessToken: string; gitlabAccessToken: string}
	) => {
		try {
			const {data, status} = await axios.post(apiPath.projectSettings, body, {
				transformRequest: (requestData, headers) => {
					if (headers) delete headers.common;
					requestData = body;
					return JSON.stringify(requestData);
				},
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json; charset=utf-8',
					Accept: 'application/json',
				},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteProject = async (token: UUID, apikey: string) => {
		try {
			const {data, status} = await axios.delete(apiPath.deleteProject(apikey), {
				transformRequest: (requestData, headers) => {
					if (headers) delete headers.common;
				},
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

/* eslint-disable no-param-reassign */
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class AnalyticsService {
	static getAnalytics = async (token: UUID, project: string) => {
		try {
			const apiUrl = apiPath.analytics(project);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static reloadAnalytics = async (token: UUID, project: string) => {
		try {
			const apiUrl = apiPath.reloadAnalytics;
			const {data, status} = await axios.post(
				apiUrl,
				{project},
				{
					transformRequest: (requestData, headers) => {
						if (headers) delete headers.common;
						requestData = {
							project,
						};
						return JSON.stringify(requestData);
					},
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json; charset=utf-8',
						Accept: 'application/json',
					},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

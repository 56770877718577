import {action, makeObservable, observable} from 'mobx';

type banModal = {
	type: string;
	targetName: string;
	targetId: number;
	externalRoomId: string;
	getReports?: () => void;
} | null;

class CreateRoomService {
	@observable
	public roomModalVisible = false;

	@observable
	public adminModalVisible = false;

	@observable
	public roleModalVisible = false;

	@observable
	public updateAdminModalVisible = false;

	@observable
	public roomTalkersModalVisible = false;

	@observable
	public banModalVisible = false;

	@observable
	public agoraRecordModal = false;

	@observable
	public pollModalVisible = false;

	@observable
	public createPollModalVisible = false;

	@observable
	public roomSettingsModalVisible = false;

	@observable
	public banModal: banModal = null;

	@observable
	public pollRoomModalVisible = false;

	@observable
	public reactionModalVisible = false;

	@observable
	public reactionModalElem = null;

	@observable
	public roomRecordModalVisible = false;

	@observable
	public addTalkerModalVisible = false;

	@observable
	public changePasswordModalVisible = false;

	@observable
	public exportModalVisible = false;

	@observable
	public promptHistoryModalVisible = false;

	@observable
	public editedMessageModalVisible = false;

	@observable
	public webhooksResponseModalVisible = false;

	@observable
	public badgesModalVisible = false;

	@observable
	public imageGalleryModalVisible = false;

	@observable
	public imageGalleryFiles: {url: string; isVisible: boolean}[] = [];

	@observable
	public imageGalleryMessageId: number | null = null;

	@observable
	public reactionsModalVisible = false;

	@observable
	public subscribeModalVisible = false;

	@action
	public showRoomModal = () => {
		this.roomModalVisible = true;
	};

	@action
	public hideRoomModal = () => {
		this.roomModalVisible = false;
	};

	@action
	public showAdminModal = () => {
		this.adminModalVisible = true;
	};

	@action
	public hideAdminModal = () => {
		this.adminModalVisible = false;
	};

	@action
	public showUpdateAdminModal = () => {
		this.updateAdminModalVisible = true;
	};

	@action
	public hideUpdateAdminModal = () => {
		this.updateAdminModalVisible = false;
	};

	@action
	public showRoleModal = () => {
		this.roleModalVisible = true;
	};

	@action
	public hideRoleModal = () => {
		this.roleModalVisible = false;
	};

	@action
	public showRoomTalkersModal = () => {
		this.roomTalkersModalVisible = true;
	};

	@action
	public hideRoomTalkersModal = () => {
		this.roomTalkersModalVisible = false;
	};

	@action
	public showBanModal = (banModal: banModal) => {
		this.banModal = banModal;
		this.banModalVisible = true;
	};

	@action
	public hideBanModal = () => {
		this.banModalVisible = false;
	};

	@action
	public showAgoraRecordModal = () => {
		this.agoraRecordModal = true;
	};

	@action
	public hideAgoraRecordModal = () => {
		this.agoraRecordModal = false;
	};

	@action
	public togglePollModalVisible = (visible: boolean) => {
		this.pollModalVisible = visible;
	};

	@action
	public toggleCreatePollModalVisible = (visible: boolean) => {
		this.createPollModalVisible = visible;
	};

	@action
	public toggleRoomSettingsModalVisible = (visible: boolean) => {
		this.roomSettingsModalVisible = visible;
	};

	@action
	public togglePollRoomModalVisible = (visible: boolean) => {
		this.pollRoomModalVisible = visible;
	};

	@action
	public toggleReactionModalVisible = (visible: boolean) => {
		this.reactionModalVisible = visible;
	};

	@action
	public setReactionModalElem = (elem: any) => {
		this.reactionModalElem = elem;
	};

	@action
	public toggleRoomRecordModalVisible = (visible: boolean) => {
		this.roomRecordModalVisible = visible;
	};

	@action
	public toggleAddTalkerModalVisible = (visible: boolean) => {
		this.addTalkerModalVisible = visible;
	};

	@action
	public toggleChangePasswordModalVisible = (visible: boolean) => {
		this.changePasswordModalVisible = visible;
	};

	@action
	public toggleExportModalVisible = (visible: boolean) => {
		this.exportModalVisible = visible;
	};

	@action
	public togglePromptHistoryModalVisible = (visible: boolean) => {
		this.promptHistoryModalVisible = visible;
	};

	@action
	public toggleEditedMessageModalVisible = (visible: boolean) => {
		this.editedMessageModalVisible = visible;
	};

	@action
	public toggleWebhooksResponseModalVisible = (visible: boolean) => {
		this.webhooksResponseModalVisible = visible;
	};

	@action
	public toggleBadgesModalVisible = (visible: boolean) => {
		this.badgesModalVisible = visible;
	};

	@action
	public toggleImageGalleryModalVisible = (visible: boolean) => {
		this.imageGalleryModalVisible = visible;
	};

	@action
	public setImageGalleryFiles = (value: {url: string; isVisible: boolean}[]) => {
		this.imageGalleryFiles = value;
	};

	@action
	public setImageGalleryMessageId = (value: number) => {
		this.imageGalleryMessageId = value;
	};

	@action
	public setReactionsModalVisible = (value: boolean) => {
		this.reactionsModalVisible = value;
	};

	@action
	public setSubscribeModalVisible = (value: boolean) => {
		this.subscribeModalVisible = value;
	};

	constructor() {
		makeObservable(this);
	}
}
export default new CreateRoomService();

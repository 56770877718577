import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form, Button, Spinner, Image} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import ChangePasswordModal from 'components/modals/changePasswordModal/ChangePasswordModal';
import useAdminProxy from 'hooks/useAdminProxy';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import appService from 'store/appService';
import classNames from 'classnames';
import {Theme} from 'models/enums/Theme.enum';

const ProfileSettings: FunctionComponent = function ProfileSettings() {
	const translations = useL10n();
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [visibleSavePreloader, setVisibleSavePreloader] = useState(false);
	const [validated, setValidated] = useState(false);
	const [myAdmin, setMyAdmin] = useState({
		name: '',
		chatName: '',
		chatPic: '',
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [pic, setPic] = useState('');
	const [image, setImage] = useState<File>();

	const {admin, adminData} = useLocalObservable(() => userServices);
	const {toggleChangePasswordModalVisible} = useLocalObservable(() => modalServices);
	const {appTheme} = useLocalObservable(() => appService);

	const {getAdmin, updateAdmin, uploadAdminPic} = useAdminProxy();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMyAdmin({
			...myAdmin,
			[event.target.name]: value,
		});
	};

	const save = async () => {
		setVisibleSavePreloader(true);
		if (image) {
			const response = await uploadAdminPic(image);
			if (response.status === ResponseStatus.SUCCESS) {
				if (response.data.pic) await updateAdmin({...myAdmin, chatPic: response.data.pic});
			}
		} else await updateAdmin(myAdmin);
		setVisibleSavePreloader(false);

		setIsButtonDisabled(true);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else {
			save();
		}
	};

	const clear = async () => {
		setValidated(false);
		setIsButtonDisabled(true);
		await getAdmin(adminData.adminId);
	};

	const getAdminWithServices = async () => {
		setVisiblePreloader(true);
		await getAdmin(adminData.adminId);
		setVisiblePreloader(false);
	};

	const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setPic(URL.createObjectURL(file));
				setImage(file);
			}
		}
	};

	useEffect(() => {
		if (myAdmin && admin)
			setIsButtonDisabled(
				myAdmin.name === admin.name && myAdmin.chatName === admin.chatName && !image
			);
	}, [myAdmin, admin, image]);

	useEffect(() => {
		if (admin) {
			setPic(admin.chatPic);
			setMyAdmin({
				name: admin.name,
				chatName: admin.chatName,
				chatPic: admin.chatPic,
			});
		}
	}, [admin]);

	useEffect(() => {
		if (adminData?.adminId) getAdminWithServices();
	}, [adminData]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.profileSettings.profile}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.profileSettings}</Breadcrumb.Item>
					</Breadcrumb>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme.toLowerCase()} />
						</div>
					)}

					{!visiblePreloader && (
						<div className='block'>
							<p className='h3 mb-4'>{translations.sidebar.profileSettings}</p>
							<div className='block w-50'>
								<Form noValidate validated={validated} onSubmit={handleSubmit}>
									<div className='bm-4'>
										<p className='h5 mb-5'>{translations.profileSettings.access}</p>
										<div className='mb-4 w-50'>
											<p>{translations.profileSettings.login}</p>
											<Form.Control
												name='name'
												placeholder={translations.profileSettings.login}
												value={myAdmin.name}
												onChange={onChangeHandler}
												required
											/>
										</div>

										<div className='mb-5'>
											<p>{translations.profileSettings.password}</p>
											<Button
												type='button'
												variant={appTheme.toLowerCase()}
												onClick={() => toggleChangePasswordModalVisible(true)}>
												{translations.profileSettings.changePassword}
											</Button>
										</div>
									</div>
									<div>
										<p className='h5 mb-4'>{translations.profileSettings.userProfile}</p>
										<div className='mb-4 w-50'>
											<p>{translations.profileSettings.userName}</p>
											<Form.Control
												name='chatName'
												placeholder={translations.profileSettings.userName}
												value={myAdmin.chatName}
												onChange={onChangeHandler}
												required
											/>
										</div>
										<div className='mb-5'>
											<Form noValidate validated={validated} onSubmit={handleSubmit}>
												<Form.Group className='mb-3' controlId='adminPic'>
													{pic && <Image roundedCircle src={pic} className='image-preview mx-2' />}

													<Form.Control type='file' hidden onChange={readFile} />
													<Form.Label
														className={classNames('btn mb-0', {
															'btn-light': appTheme === Theme.LIGHT,
															'btn-dark': appTheme === Theme.DARK,
														})}>
														{translations.btns.addAvatar}
													</Form.Label>
												</Form.Group>
											</Form>
										</div>
									</div>
									{visibleSavePreloader ? (
										<div>
											<Spinner
												animation='border'
												variant={appTheme === Theme.DARK ? 'light' : 'dark'}
											/>
										</div>
									) : (
										<div>
											<Button type='submit' variant='success' disabled={isButtonDisabled}>
												{translations.btns.save}
											</Button>
											&nbsp;
											<Button
												type='button'
												variant='outline-secondary'
												onClick={clear}
												disabled={isButtonDisabled}>
												{translations.btns.cancel}
											</Button>
										</div>
									)}
								</Form>
							</div>
						</div>
					)}
				</Col>
			</Row>
			<ChangePasswordModal />
		</Container>
	);
};

export default observer(ProfileSettings);

import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class StickersService {
	static getStickerPacks = async (token: string) => {
		try {
			const url = apiPath.stickers;
			const {data, status} = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStickerPackById = async (token: string, id: number) => {
		try {
			const url = `${apiPath.stickers}/${id}`;
			const {data, status} = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addStickerPack = async (token: string, name: string) => {
		try {
			const url = apiPath.stickers;
			const body = {
				name,
			};
			const {data, status} = await axios.post(url, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateStickerPack = async (
		token: string,
		value: {
			id: number;
			name: string;
			isEnabled?: boolean;
		}
	) => {
		try {
			const url = `${apiPath.stickers}/${value.id}`;
			const body: any = {};
			if (value.name) body.name = value.name;
			if (typeof value.isEnabled !== undefined) body.isEnabled = value.isEnabled;

			const {data, status} = await axios.patch(url, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteStickerPack = async (token: string, stickerPackId: number) => {
		try {
			const url = `${apiPath.stickers}/${stickerPackId}`;

			const {data, status} = await axios.delete(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addSticker = async (
		token: string,
		pics: {file: File; emojis?: string[]}[] | null,
		stickerPackId?: number
	) => {
		try {
			const formData = new FormData();
			if (pics?.length) {
				let picEmojis = {};
				pics.forEach(pic => {
					formData.append('pic', pic.file, pic.file.name);
					if (pic.emojis) {
						picEmojis = {...picEmojis, [pic.file.name]: pic.emojis};
					}
				});
				formData.append('emojis', JSON.stringify(picEmojis));
				if (stickerPackId) formData.append('stickerPackId', `${stickerPackId}`);
			}

			const apiUrl = apiPath.sticker;
			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response?.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};

	static deleteSticker = async (token: string, id: number) => {
		try {
			const apiUrl = apiPath.getSticker(id);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateSticker = async (token: string, stickerData: any) => {
		try {
			const apiUrl = apiPath.getSticker(stickerData.id || 0);
			const {data, status} = await axios.patch(
				apiUrl,
				{emojis: stickerData.emojis},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

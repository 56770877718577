/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Container, Form, InputGroup, Button} from 'react-bootstrap';

import {useParams} from 'react-router-dom';

import useL10n from 'l10n/useL10n';

import appService from 'store/appService';

import checkRealTextLength from 'utils/checkTextLength';
import {getFileSize, getContrastColor} from 'utils/helpers';

import {HighlightGradient, PICKER} from 'models/enums/Highlights.enum';

import UploadImage from 'components/uploadImage/uploadImage';
import Template from 'components/highlights/Template';
import CustomSettings from 'components/highlights/templateDataSettings';
import settingsServices from 'store/settingsServices';
import offerService from 'store/offerService';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';
import {Theme} from 'models/enums/Theme.enum';
import useOffers from 'hooks/useOffers';
import {Highlight} from 'models/highlights';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

interface IOfferContent {
	nextStep: () => void;
	warning: boolean;
	warningLink: boolean;
}

const OfferContent: FunctionComponent<IOfferContent> = function OfferContent({
	nextStep,
	warning,
	warningLink,
}) {
	const {slug} = useParams<{
		slug: string;
	}>();
	const formRef = useRef<HTMLFormElement>(null);

	const translations = useL10n();

	const {appTheme} = useLocalObservable(() => appService);
	const {currentOffer, currentStep, setCurrentOffer, setCurrentStep} = useLocalObservable(
		() => offerService
	);
	const {language} = useLocalObservable(() => appService);
	const {brandingColors, customHighlightColors} = useLocalObservable(() => settingsServices);

	const {updateOffer} = useOffers();

	const [validated, setValidated] = useState(false);
	const [ad, setAd] = useState<Highlight>({
		id: 0,
		title: '',
		titleLength: 0,
		text: '',
		textLength: 0,
		link: '',
		linkTextLength: 18,
		linkText: translations.highlights.learnMore,
		pic: '',
		picFile: null,
		template: null,
		openType: LinkOpenType.NEW_WINDOW,
	});
	const [isLink, setIsLink] = useState(true);
	const [color, setColor] = useState('');
	const [picker, setPicker] = useState('');
	const [gradient, setGradient] = useState(false);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setAd({
			...ad,
			[event.target.name]: value.trim().length ? value : value.trim(),
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});

		setCurrentOffer({
			...currentOffer,
			[event.target.name]: value.trim().length ? value : value.trim(),
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});
	};

	const onChangeIsLink = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsLink(checked);

		setAd({
			...ad,
			linkText: checked ? translations.highlights.learnMore : '',
			link: checked ? ad.link : '',
			linkTextLength: checkRealTextLength(checked ? translations.highlights.learnMore : ''),
		});

		setCurrentOffer({
			...currentOffer,
			linkText: checked ? translations.highlights.learnMore : '',
			link: checked ? currentOffer.link : '',
			linkTextLength: checkRealTextLength(checked ? translations.highlights.learnMore : ''),
		});
	};

	const onChangeOpenTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setAd({
			...ad,
			openType: value as LinkOpenType,
		});

		setCurrentOffer({
			...currentOffer,
			openType: value as LinkOpenType,
		});
	};

	const setPic = (file: File | null) => {
		setAd({
			...ad,
			pic: file ? URL.createObjectURL(file) : '',
		});
		setCurrentOffer({
			...currentOffer,
			pic: file ? URL.createObjectURL(file) : '',
			picFile: file,
		});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		const btnName = e.nativeEvent.submitter.getAttribute('name');
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else btnName === 'save-offer' ? updateOffer() : nextStep();
	};

	const onPrevClickHandler = () => {
		setCurrentStep(currentStep - 1);
	};

	const isCustomTemplate =
		currentOffer.template !== undefined &&
		currentOffer.template !== null &&
		(currentOffer.template < 3 || currentOffer.template === 11);

	const isImageTemplate = currentOffer.template === 11;

	const setData = () => {
		let highlight = {...currentOffer};
		if (slug) {
			setIsLink(currentOffer.link !== '');
			if (highlight.template !== 10) delete highlight.templateData;
		} else setIsLink(currentOffer.linkText !== '');

		if (currentOffer.link === '' && currentOffer.linkText !== '')
			highlight.linkText = translations.highlights.learnMore;
		if (currentOffer.title === null) highlight.title = '';
		if (currentOffer.text === null) highlight.text = '';
		if (!isCustomTemplate && currentOffer.pic) {
			highlight.pic = '';
			highlight.picFile = null;
		}

		if (highlight.template === 10 && !highlight.templateData) {
			highlight = {
				...highlight,
				templateData: {
					alignment: 'left',
					bgColor: customHighlightColors?.bg || brandingColors.bg,
					btnColor: customHighlightColors?.btn || brandingColors.btn,
					btnTextColor: getContrastColor(customHighlightColors?.btn || brandingColors.btn),
					textColor: getContrastColor(
						customHighlightColors?.gradient?.from || customHighlightColors?.bg || brandingColors.bg
					),
					gradient: customHighlightColors?.gradient?.from !== undefined,
					gradientFrom: customHighlightColors?.gradient?.from,
					gradientTo: customHighlightColors?.gradient?.to,
					gradientDirection: HighlightGradient.TOP,
				},
			};
		}

		if (highlight.template === 11) {
			highlight.text = '';
			highlight.title = '';
			highlight.linkText = '';
			if (highlight.link) setIsLink(true);
		}

		setGradient(highlight.templateData?.gradient || false);
		setCurrentOffer(highlight);
		setAd(highlight);
	};

	useEffect(() => {
		if (slug) {
			if (currentOffer.id) setData();
			return;
		}
		setData();
	}, [currentOffer.id, language]);

	useEffect(() => {
		if (color) {
			if (picker === PICKER.BG || picker === PICKER.GRADIENT_FROM)
				setCurrentOffer({
					...currentOffer,
					templateData: {
						...currentOffer.templateData,
						textColor: getContrastColor(color),
					},
				});

			if (picker === PICKER.BTN)
				setCurrentOffer({
					...currentOffer,
					templateData: {
						...currentOffer.templateData,
						btnTextColor: getContrastColor(color),
					},
				});
		}
	}, [color]);

	useEffect(() => {
		document.getElementById('create-offer')?.scrollIntoView({behavior: 'smooth'});
	}, []);

	return (
		<section className='offer__content'>
			<div className='d-flex'>
				<div className='w-50 mb-5'>
					<Form
						noValidate
						validated={validated}
						onSubmit={handleSubmit}
						ref={formRef}
						id='offerContentForm'>
						<div className='block'>
							{warning && (
								<p className='text-danger'>
									<b>{translations.welcome.warning}</b>
								</p>
							)}
							<p className='h5 mb-4'>{translations.offers.contentSettings.title}</p>
							{isCustomTemplate && (
								<div className='mb-3'>
									<Form.Label>{translations.offers.contentSettings.picture}</Form.Label>
									<p>
										<small>{translations.imageReqs.imageWeight(getFileSize(10485760))}</small>
										<small>
											{translations.imageReqs.imageMinSize(
												!isImageTemplate ? [600, 400] : [600, 240]
											)}
										</small>
									</p>

									<UploadImage
										id='uploadHighlight'
										setPic={setPic}
										pic={ad.pic}
										objectMinSize={
											!isImageTemplate ? {width: 600, height: 400} : {width: 600, height: 240}
										}
										size={10485760}
										acceptedFileTypes='.jpg, .jpeg, .png, .gif, .svg'
									/>
								</div>
							)}

							{!isImageTemplate && (
								<>
									<Form.Group controlId='highlightTitle' className='mb-2'>
										<Form.Label>{translations.highlights.title}</Form.Label>

										<Form.Control
											placeholder={translations.offers.contentSettings.addTitle}
											name='title'
											required={!ad.text}
											value={ad.title}
											onChange={onChangeHandler}
											maxLength={23}
										/>
										<p className='text-end text-secondary'>
											<small>{ad.titleLength || 0}/23</small>
										</p>
									</Form.Group>

									<Form.Group controlId='highlightText' className='mb-2'>
										<Form.Label>{translations.highlights.text}</Form.Label>

										<Form.Control
											as='textarea'
											rows={4}
											placeholder={translations.offers.contentSettings.addText}
											name='text'
											required={!ad.title}
											value={ad.text}
											onChange={onChangeHandler}
											maxLength={180}
										/>
										<p className='text-end text-secondary'>
											<small>{ad.textLength || 0}/180</small>
										</p>
									</Form.Group>
									<Form.Group controlId='highlightText' className='mb-2'>
										<Form.Label>{translations.highlights.linkText}</Form.Label>
										{warningLink && (
											<p className='text-danger'>
												<b>{translations.welcome.warningLink}</b>
											</p>
										)}
										<InputGroup>
											<InputGroup.Checkbox
												className='checkbox-black'
												checked={isLink}
												onChange={onChangeIsLink}
											/>

											<Form.Control
												placeholder={translations.highlights.linkText}
												name='linkText'
												required={isLink}
												disabled={!isLink}
												value={ad.linkText}
												onChange={onChangeHandler}
												maxLength={28}
											/>
										</InputGroup>
										<p className='text-end text-secondary'>
											<small>{ad.linkTextLength || 0}/28</small>
										</p>
									</Form.Group>
								</>
							)}
							{isLink && (
								<>
									<Form.Group controlId='highlightLink' className='mb-3'>
										<Form.Label>{translations.highlights.link}</Form.Label>

										<Form.Control
											placeholder={translations.highlights.link}
											name='link'
											required={isLink}
											value={ad.link}
											onChange={onChangeHandler}
											type='url'
										/>
									</Form.Group>
									<Form.Group className='mb-3' controlId='linkOpenType'>
										<div>
											<Form.Check
												inline
												className='checkbox-black'
												type='radio'
												label={translations.linkOpenType.newWindow}
												id='linkOpenType2'
												value={LinkOpenType.NEW_WINDOW}
												required
												onChange={onChangeOpenTypeHandler}
												checked={ad.openType === LinkOpenType.NEW_WINDOW}
											/>
										</div>
										<div>
											<Form.Check
												inline
												className='checkbox-black'
												type='radio'
												label={translations.linkOpenType.currentWindow}
												id='linkOpenType1'
												value={LinkOpenType.CURRENT_WINDOW}
												required
												onChange={onChangeOpenTypeHandler}
												checked={ad.openType === LinkOpenType.CURRENT_WINDOW}
											/>
										</div>

										<div>
											<Form.Check
												inline
												className='checkbox-black'
												type='radio'
												label={translations.linkOpenType.postMessage}
												id='linkOpenType3'
												value={LinkOpenType.POST_MESSAGE}
												required
												onChange={onChangeOpenTypeHandler}
												checked={ad.openType === LinkOpenType.POST_MESSAGE}
											/>
										</div>
									</Form.Group>
								</>
							)}

							{currentOffer.template === 10 && (
								<CustomSettings
									color={color}
									picker={picker}
									gradient={gradient}
									setPicker={setPicker}
									setColor={setColor}
									setGradient={setGradient}
									isOffer
								/>
							)}
						</div>
					</Form>
				</div>
				<div>
					{currentOffer.template !== null && currentOffer.template !== undefined && (
						<Template template={currentOffer.template} ad={ad} isSettings isLink={isLink} isOffer />
					)}
				</div>
			</div>
			<div className='offer__footer'>
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					onClick={onPrevClickHandler}>
					<BsArrowLeftCircleFill />
					&nbsp;&nbsp;
					{translations.welcome.steps.btns.prev}
				</Button>

				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					className='mx-2'
					form='offerContentForm'
					type='submit'>
					{translations.welcome.steps.btns.next}
					&nbsp;&nbsp;
					<BsArrowRightCircleFill />
				</Button>

				{slug && (
					<Button
						name='save-offer'
						variant='success'
						className='d-flex'
						type='submit'
						form='offerContentForm'>
						{translations.btns.save}
					</Button>
				)}
			</div>
		</section>
	);
};

export default observer(OfferContent);

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';

export default class BanService {
	static getBanned = async (token: UUID, limit: number, offset: number, order: string) => {
		try {
			const apiUrl = apiPath.getBanned(limit, offset, order);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBannedHistory = async (token: UUID, limit: number, offset: number, order: string) => {
		try {
			const apiUrl = apiPath.getBannedHistory(limit, offset, order);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static toggleUserBan = async (
		banData: {
			userId: number;
			externalRoomId?: string;
			value: boolean;
			expires?: string | null;
			description?: string;
			reason?: number;
		},
		token: UUID
	) => {
		try {
			const apiUrl = apiPath.toggleUserBan;
			type ban = {
				userId: number;
				value: boolean;
				externalRoomId?: string;
				expires?: string | null;
				description?: string;
				reason?: number;
			};
			const body: ban = {
				userId: banData.userId,
				value: banData.value,
			};

			if (banData.externalRoomId) body.externalRoomId = banData.externalRoomId;
			if (banData.expires) body.expires = banData.expires;
			if (banData.description) body.description = banData.description;
			if (banData.reason !== undefined) body.reason = banData.reason;

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchUserBan = async (
		token: UUID,
		banId: number,
		payload: {description?: string; isApproved?: boolean}
	) => {
		try {
			const apiUrl = apiPath.patchUserBan(banId);

			const {data, status} = await axios.patch(apiUrl, payload, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static toggleTalkerBan = async (
		banData: {
			talkerId: number;
			externalRoomId: string;
			value: boolean;
			expires?: string | null;
			description?: string;
			reason?: number;
		},
		token: UUID
	) => {
		try {
			const apiUrl = apiPath.toggleTalkerBan;
			type ban = {
				talkerId: number;
				value: boolean;
				externalRoomId: string;
				expires?: string | null;
				description?: string;
				reason?: number;
			};
			const body: ban = {
				talkerId: banData.talkerId,
				value: banData.value,
				externalRoomId: banData.externalRoomId,
			};
			if (banData.expires) body.expires = banData.expires;
			if (banData.description) body.description = banData.description;
			if (banData.reason !== undefined) body.reason = banData.reason;

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchTalkerBan = async (
		token: UUID,
		banId: number,
		payload: {description?: string; isApproved?: boolean}
	) => {
		try {
			const apiUrl = apiPath.patchTalkerBan(banId);

			const {data, status} = await axios.patch(apiUrl, payload, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static toggleTalkerMute = async (
		muteData: {talkerId: number; externalRoomId: string; value: boolean},
		token: UUID
	) => {
		try {
			const apiUrl = apiPath.toggleTalkerMute;

			const body = {
				talkerId: muteData.talkerId,
				externalRoomId: muteData.externalRoomId,
				value: muteData.value,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAutoBanned = async (token: UUID, limit: number, offset: number, order: string) => {
		try {
			const apiUrl = apiPath.getAutoBanned(limit, offset, order);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Container, Spinner} from 'react-bootstrap';

import userServices from 'store/userServices';
import lastMessagesService from 'store/messagesServices';

import ResponseStatus from 'models/enums/ResponseStatus.enum';

import MessageService from 'services/api/MessageService';

import Chat from 'components/chat/Chat';
import ChatThrottling from 'components/chat/ChatThrottling';

import toastServices from 'store/toastServices';
import appService from 'store/appService';

import {TALKER_STUB} from 'constants/constants';
import {Theme} from 'models/enums/Theme.enum';
import MessageType from 'models/enums/MessageType';
import {Message} from 'models/room';

const LastMessages: FunctionComponent = function LastMessages() {
	const {accessToken} = useLocalObservable(() => userServices);
	const {
		messages,
		setMessages,
		addMessages,
		addMessagesNext,
		setSocketMessages,
		getLastViewedMessageId,
		ignoreAutoposted,
		hideImages,
	} = useLocalObservable(() => lastMessagesService);
	const {hideToasts} = useLocalObservable(() => toastServices);

	const {projectId, appTheme} = useLocalObservable(() => appService);

	const [limit, setLimit] = useState(50);
	const [scrollToBottom, setScrollToBottom] = useState(false);
	const [messagesLoaded, setMessagesLoaded] = useState(false);

	const getMessages = async () => {
		const response = await MessageService.getMessages({
			token: accessToken,
			ignoreAutoposted: Number(ignoreAutoposted),
			hideImages,
		});
		if (response.status === ResponseStatus.SUCCESS) {
			setMessages(
				response.data.filter(
					(el: Message) =>
						(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
				)
			);
			setScrollToBottom(true);
			setMessagesLoaded(true);
			setSocketMessages([]);
		}
	};

	const getPreviousMessages = async () => {
		const response = await MessageService.getMessages({
			token: accessToken,
			ignoreAutoposted: Number(ignoreAutoposted),
			limit,
			type: {
				name: 'lastId',
				id: messages[0].id,
			},
			hideImages,
		});
		if (response.status === ResponseStatus.SUCCESS) {
			addMessages(
				response.data.filter(
					(el: Message) =>
						(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
				)
			);
		}
		return response.status;
	};

	const getNextMessages = async () => {
		const response = await MessageService.getMessages({
			token: accessToken,
			ignoreAutoposted: Number(ignoreAutoposted),
			limit,
			type: {
				name: 'firstId',
				id: messages[messages.length - 1].id,
			},
			hideImages,
		});
		if (response.status === ResponseStatus.SUCCESS) {
			addMessagesNext(
				response.data
					.filter(
						(el: Message) =>
							(el.type && el.type !== MessageType.GAMBLE && el.type !== MessageType.BET) || el.text
					)
					.map((msg: any) => {
						if (msg.talker) {
							return msg;
						}
						return {...msg, talker: TALKER_STUB, text: ''};
					})
			);
		}
		return {
			status: response.status,
			data: response.data,
		};
	};

	const getAroundMessages = async (messageId: number) => {
		if (messageId) {
			const response = await MessageService.getAroundMessages({
				token: accessToken,
				messageId,
				limit: 100,
				ignoreAutoposted: Number(ignoreAutoposted),
				hideImages,
			});
			if (response.status === ResponseStatus.SUCCESS) {
				setMessages(
					response.data
						.filter(
							(el: Message) =>
								(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
						)
						.map((msg: any) => {
							if (msg.talker) {
								return msg;
							}
							return {...msg, talker: TALKER_STUB, text: ''};
						})
				);
				setMessagesLoaded(true);
			}
		}
	};

	useEffect(() => {
		const lastViewedMessageId = getLastViewedMessageId(projectId);
		accessToken && lastViewedMessageId ? getAroundMessages(+lastViewedMessageId) : getMessages();
	}, [accessToken, ignoreAutoposted, hideImages]);

	useEffect(() => {
		return () => {
			hideToasts();
		};
	}, []);

	return (
		<Container fluid>
			{!messagesLoaded ? (
				<div className='text-center mt-5'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			) : (
				<div className='chat-wrapper'>
					<ChatThrottling />
					<Chat
						scrollToBottom={scrollToBottom}
						getPreviousMessages={getPreviousMessages}
						getNextMessages={getNextMessages}
						getLastMessages={getMessages}
						getAroundMessages={getAroundMessages}
						isLastMessages
					/>
				</div>
			)}
		</Container>
	);
};

export default observer(LastMessages);

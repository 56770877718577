import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';

import {Button, Modal, Form, FloatingLabel} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import createRoomService from 'store/modalServices';

import '../modal.scss';
import 'react-datepicker/dist/react-datepicker.css';

import RoomService from 'services/api/RoomService';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Project} from 'models/enums/Project.enum';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';

interface ICreateRoomModal {
	onCloseSuccess: (externalRoomId: string) => void;
	onCloseError: (error: string) => void;
}

const createRoomModal: FunctionComponent<ICreateRoomModal> = function createRoomModal({
	onCloseSuccess,
	onCloseError,
}) {
	const {roomModalVisible, hideRoomModal} = useLocalObservable(() => createRoomService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {projectId} = useLocalObservable(() => appService);

	const [startDate, setStartDate] = useState<Date | null>();
	const [endDate, setEndDate] = useState<Date | null>();
	const [validated, setValidated] = useState(false);
	const [pic, setPic] = useState<Blob | null>();
	const [image, setImage] = useState('');
	const [roomData, setRoomData] = useState({
		externalRoomId: '',
		name: '',
		about: '',
		isSpeak: false,
		autoRecord: false,
	});

	const translations = useL10n();

	const project = projectId.split('-')[0];

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		if (event.target.name === 'externalRoomId') {
			value = value.replace(/[^a-zA-Z0-9-]/i, '').trim();
		}

		if (event.target.name === 'about') {
			value = value.replace(/\r?\n/g, ' ');
		}
		setRoomData({
			...roomData,
			[event.target.name]: value,
		});
	};

	const clear = () => {
		setRoomData({
			externalRoomId: '',
			name: '',
			about: '',
			isSpeak: false,
			autoRecord: false,
		});
		setStartDate(null);
		setEndDate(null);
		setValidated(false);
		setImage('');
		setPic(null);
	};

	const createRoom = async () => {
		const room = {
			...roomData,
			startTime: startDate ? startDate.toISOString() : null,
			endTime: endDate ? endDate.toISOString() : null,
		};
		const response =
			project === Project.TINKOFF
				? await RoomService.createRoomPic(room, accessToken, pic)
				: await RoomService.createRoom(room, accessToken);

		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.error) {
				onCloseError(response.data.error);
			} else {
				onCloseSuccess(roomData.externalRoomId);
				clear();
				hideRoomModal();
			}
		}
	};

	const onModalClose = () => {
		clear();
		hideRoomModal();
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else createRoom();
	};

	const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setPic(file);
				setImage(URL.createObjectURL(file));
			}
		}
	};

	const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setRoomData({
			...roomData,
			[event.target.name]: checked,
		});
	};

	return (
		<Modal
			className='modal--dark'
			show={roomModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton className='text-primary'>
				<Modal.Title className='text-primary'> {translations.modals.createRoom.title} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className='mb-3' controlId='formRoomId'>
						<Form.Label className='text-light'>
							{translations.modals.createRoom.externakRoomId}{' '}
						</Form.Label>
						<FloatingLabel
							controlId='floatingId'
							label={translations.modals.createRoom.externakRoomId}
							className='text-secondary'>
							<Form.Control
								placeholder={translations.modals.createRoom.externakRoomId}
								name='externalRoomId'
								required
								value={roomData.externalRoomId}
								onChange={onChangeHandler}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								{translations.modals.createRoom.externakRoomIdWarn}
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formRoomName'>
						<Form.Label className='text-light'>{translations.modals.createRoom.name} </Form.Label>
						<FloatingLabel
							controlId='floatingName'
							label={translations.modals.createRoom.name}
							className='text-secondary'>
							<Form.Control
								placeholder={translations.modals.createRoom.name}
								name='name'
								required
								value={roomData.name}
								onChange={onChangeHandler}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								{translations.modals.createRoom.nameWarn}
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					{project === Project.TINKOFF && (
						<Form.Group className='mb-3' controlId='formRoomName'>
							<Form.Label className='text-light'>
								{translations.modals.createRoom.description}{' '}
							</Form.Label>
							<FloatingLabel
								controlId='floatingName'
								label={translations.modals.createRoom.description}
								className='text-secondary'>
								<Form.Control
									as='textarea'
									rows={5}
									size='sm'
									maxLength={30}
									placeholder={translations.modals.createRoom.description}
									name='about'
									required
									value={roomData.about}
									onChange={onChangeHandler}
								/>
								<Form.Control.Feedback type='invalid' tooltip>
									{translations.modals.createRoom.descriptionWarn}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
					)}

					<Form.Group className='mb-3' controlId='formDateStart'>
						<Form.Label className='text-light'>
							{translations.modals.createRoom.dateStart}
						</Form.Label>
						<DatePicker
							className='form-control'
							selected={startDate}
							placeholderText={translations.modals.createRoom.dateStart}
							required={project === Project.TINKOFF}
							dateFormat='dd.MM.yyyy, HH:mm'
							locale='ru'
							timeInputLabel={`${translations.modals.createRoom.time}:`}
							showTimeInput
							onChange={(date: Date) => setStartDate(date)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formDateStart'>
						<Form.Label className='text-light'>{translations.modals.createRoom.dateEnd}</Form.Label>
						<DatePicker
							className='form-control'
							selected={endDate}
							placeholderText={translations.modals.createRoom.dateEnd}
							required={project === Project.TINKOFF}
							dateFormat='dd.MM.yyyy, HH:mm'
							locale='ru'
							timeInputLabel={`${translations.modals.createRoom.time}:`}
							showTimeInput
							onChange={(date: Date) => setEndDate(date)}
						/>
					</Form.Group>

					{project === Project.TINKOFF && (
						<Form.Group controlId='formFile' className='text-white mb-3'>
							<Form.Label>{translations.table.pic}</Form.Label>
							<Form.Control type='file' required onChange={readFile} />
						</Form.Group>
					)}

					{project === Project.TINKOFF && (
						<Form.Group className='mb-3' controlId='formIsSpeak'>
							<Form.Check
								name='isSpeak'
								type='switch'
								id='isSpeak'
								className='text-white'
								label={translations.room.switch}
								onChange={onCheck}
								checked={roomData.isSpeak}
							/>
						</Form.Group>
					)}

					<Modal.Footer>
						<Button variant='secondary' onClick={hideRoomModal}>
							{translations.btns.cancel}
						</Button>
						<Button type='submit'>{translations.btns.create}</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default observer(createRoomModal);

import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {PollCreated, PollCreatedOption} from 'models/poll';
import {Room} from 'models/rooms';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

type filter = {
	key: string;
	value: string | null;
	prefix: string;
};

export default class PollService {
	static getPolls = async (
		token: UUID,
		limit: number,
		offset: number,
		order: string,
		filter?: filter | null
	) => {
		try {
			let apiUrl = apiPath.getPolls(limit, offset, order);
			if (filter) {
				let filtersString = '';

				filtersString += `&${filter.key}=${filter.prefix}${filter.prefix ? '_' : ''}${
					filter.value
				}`;

				apiUrl = apiPath.getPolls(limit, offset, order, filtersString);
			} else apiUrl = apiPath.getPolls(limit, offset, order);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPoll = async (token: UUID, pollId: number) => {
		try {
			const apiUrl = apiPath.getPoll(pollId);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setPoll = async (
		token: UUID,
		pollCreateData: PollCreated,
		pollWithPics: {value: boolean}
	) => {
		try {
			const apiUrl = apiPath.setPoll;
			let elementsWoLocalId: any = pollCreateData;
			if (pollCreateData.options) {
				elementsWoLocalId = pollCreateData.options.map((el: PollCreatedOption) => {
					if (!pollWithPics.value) {
						const {id, pic, ...elRest} = el;
						return elRest;
					}
					const {id, ...elRest} = el;
					return elRest;
				});
			}

			const formData = new FormData();

			formData.append('type', `${pollCreateData.type}`);
			formData.append('text', `${pollCreateData.text}`);
			formData.append('isMultiple', `${pollCreateData.isMultiple}`);
			formData.append('options', JSON.stringify(elementsWoLocalId));

			if (pollCreateData.startTime) {
				formData.append('status', 'PLANNED');
				formData.append('startTime', `${pollCreateData.startTime}`);
			}

			if (pollCreateData.time) {
				const sendTime = !pollCreateData.startTime
					? new Date()
					: new Date(pollCreateData.startTime);
				let hours = 0;
				let minutes = 0;
				let seconds = 0;
				if (pollCreateData.time.hours) hours = +pollCreateData.time.hours * 60 * 60 * 1000;
				if (pollCreateData.time.minutes) minutes = +pollCreateData.time.minutes * 60 * 1000;
				if (pollCreateData.time.seconds) seconds = +pollCreateData.time.seconds * 1000;

				const deleteTime = new Date(sendTime.getTime() + hours + minutes + seconds).toISOString();

				formData.append('endTime', `${deleteTime}`);
			}

			formData.append('autoSendResults', `${pollCreateData.autoSendResults || false}`);
			pollCreateData.endTime && formData.append('endTime', `${pollCreateData.endTime}`);

			pollCreateData.externalRoomIds &&
				formData.append(
					'externalRoomIds',
					Array.isArray(pollCreateData.externalRoomIds)
						? JSON.stringify(pollCreateData.externalRoomIds)
						: pollCreateData.externalRoomIds
				);

			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static uploadPollOptionPicture = async (FormData: FormData, token: UUID) => {
		try {
			const {data, status} = await axios.post(apiPath.uploadPic, FormData, {
				headers: {Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data'},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchPoll = async (
		token: UUID,
		pollId: number,
		pollCreateData: PollCreated,
		pollWithPics: {value: boolean}
	) => {
		try {
			let elementsWoLocalId: any = pollCreateData;
			if (pollCreateData.options) {
				elementsWoLocalId = pollCreateData.options.map((el: PollCreatedOption) => {
					if (!pollWithPics.value) {
						const {id, pic, ...elRest} = el;
						return elRest;
					}
					const {id, ...elRest} = el;
					return elRest;
				});
			}

			let startTime;
			let endTime;

			if (pollCreateData.time) {
				startTime = pollCreateData.startTime ? new Date(pollCreateData.startTime) : new Date();
				let hours = 0;
				let minutes = 0;
				let seconds = 0;
				if (pollCreateData.time) {
					if (pollCreateData.time.hours) hours = +pollCreateData.time.hours * 60 * 60 * 1000;
					if (pollCreateData.time.minutes) minutes = +pollCreateData.time.minutes * 60 * 1000;
					if (pollCreateData.time.seconds) seconds = +pollCreateData.time.seconds * 1000;
				}

				endTime = new Date(startTime.getTime() + hours + minutes + seconds).toISOString();
			}

			let updatedPollData: any = {
				type: pollCreateData.type,
				text: pollCreateData.text,
				isMultiple: pollCreateData.isMultiple,
				options: elementsWoLocalId,
			};

			if (pollCreateData.startTime)
				updatedPollData = {...updatedPollData, startTime: pollCreateData.startTime};

			if (endTime) updatedPollData = {...updatedPollData, endTime};
			else updatedPollData = {...updatedPollData, endTime: pollCreateData.endTime};

			const {data, status} = await axios.patch(apiPath.patchPoll(pollId), updatedPollData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deletePoll = async (pollId: number, token: UUID) => {
		try {
			const {data, status} = await axios.delete(apiPath.deletePoll(pollId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static endPoll = async (pollId: number, token: UUID, share?: boolean) => {
		try {
			let apiPathEndPoll = apiPath.endPoll(pollId);

			if (typeof share !== 'undefined') {
				apiPathEndPoll = `${apiPathEndPoll}?share=${share ? 1 : 0}`;
			}

			const {data, status} = await axios.get(apiPathEndPoll, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static checkPoll = async (token: UUID, currentPoll: PollCreated, pollId?: string) => {
		try {
			const apiUrl = apiPath.checkPoll;

			const formData = new FormData();
			currentPoll.externalRoomIds &&
				formData.append(
					'externalRoomIds',
					Array.isArray(currentPoll.externalRoomIds)
						? JSON.stringify(currentPoll.externalRoomIds)
						: currentPoll.externalRoomIds
				);

			if (pollId) {
				formData.append('excludePollIds', `[${pollId}]`);
				currentPoll.roomPolls &&
					formData.append(
						'externalRoomIds',
						JSON.stringify(
							currentPoll.roomPolls.map((el: {id: number; room: Room}) => el.room.externalRoomId)
						)
					);
			}

			currentPoll.startTime && formData.append('startTime', currentPoll.startTime);
			currentPoll.endTime && formData.append('endTime', currentPoll.endTime);

			if (currentPoll.time) {
				const startTime = currentPoll.startTime ? new Date(currentPoll.startTime) : new Date();
				let hours = 0;
				let minutes = 0;
				let seconds = 0;
				if (currentPoll.time) {
					if (currentPoll.time.hours) hours = +currentPoll.time.hours * 60 * 60 * 1000;
					if (currentPoll.time.minutes) minutes = +currentPoll.time.minutes * 60 * 1000;
					if (currentPoll.time.seconds) seconds = +currentPoll.time.seconds * 1000;
				}

				const endTime = new Date(startTime.getTime() + hours + minutes + seconds).toISOString();
				currentPoll.endTime && formData.append('endTime', endTime);
			}

			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Modal, Form, Button, Alert} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import {ExportTypes} from 'models/enums/ExportTypes.enum';

import DatePicker from 'react-datepicker';
import ExportService from 'services/api/ExportService';
import userServices from 'store/userServices';

interface IExportModal {
	onClose: () => void;
}
const ExportModal: FunctionComponent<IExportModal> = function ExportModal({onClose}) {
	const translations = useL10n();
	const [validated, setValidated] = useState(false);
	const [exportData, setExportData] = useState<any>({
		type: '',
		from: null,
		to: null,
	});
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {exportModalVisible, toggleExportModalVisible} = useLocalObservable(() => modalServices);

	const onModalClose = () => {
		toggleExportModalVisible(false);
		setValidated(false);
		setExportData({
			type: '',
			from: null,
			to: null,
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setExportData({
			...exportData,
			[event.target.name]: value,
		});
	};

	const convertLocalToUTCDate = (value: Date) => {
		if (!value) {
			return value;
		}
		let date = new Date(value);
		date = new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds()
			)
		);
		return date.toISOString();
	};

	const exportUserActivity = async () => {
		const body = {
			from: convertLocalToUTCDate(exportData.from),
			to: convertLocalToUTCDate(exportData.to),
		};
		const response = await ExportService.exportUserActivity(accessToken, body, exportData.type);
		if (response.status === ResponseStatus.SUCCESS) {
			onModalClose();
			onClose();
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: response.data,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else exportUserActivity();
	};

	return (
		<Modal
			className='modal--export'
			show={exportModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title> {translations.export.modal.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<div className='mb-3'>
						<p className='text-secondary'>{translations.export.modal.descr}</p>
						<Form.Group className='mb-3' controlId='formRoomRole'>
							<div>
								<Form.Check
									inline
									name='type'
									type='radio'
									label={translations.export.users}
									id='export-type1'
									value={ExportTypes.USER_ACTIVITY}
									required
									onChange={onChangeHandler}
									className='checkbox-black'
								/>
							</div>
							<div>
								<Form.Check
									inline
									name='type'
									type='radio'
									label={translations.export.messages}
									id='export-type2'
									value={ExportTypes.MESSAGES}
									required
									onChange={onChangeHandler}
									className='checkbox-black'
								/>
							</div>
						</Form.Group>
					</div>
					<div className='mb-5'>
						<p className='text-secondary'>{translations.export.modal.period}</p>
						<Form.Group className='mb-3' controlId='formDateStart'>
							<DatePicker
								className='form-control'
								selected={exportData.from}
								placeholderText={translations.export.from}
								dateFormat='dd.MM.yyyy, HH:mm'
								timeInputLabel={`${translations.modals.createRoom.time}:`}
								onKeyDown={e => {
									e.preventDefault();
								}}
								showTimeInput
								onChange={(date: Date) => setExportData({...exportData, from: date})}
								required
							/>
						</Form.Group>

						<Form.Group className='mb-3' controlId='formDateStart'>
							<DatePicker
								className='form-control'
								selected={exportData.to}
								placeholderText={translations.export.to}
								dateFormat='dd.MM.yyyy, HH:mm'
								timeInputLabel={`${translations.modals.createRoom.time}:`}
								onKeyDown={e => {
									e.preventDefault();
								}}
								showTimeInput
								onChange={(date: Date) => setExportData({...exportData, to: date})}
								required
							/>
						</Form.Group>
						<Alert>{translations.export.hint}</Alert>
					</div>
					<Button variant='dark' type='submit'>
						{translations.export.modal.btnExport}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default observer(ExportModal);

import {Ban} from 'models/ban';
import moment from 'moment';

import translations from 'l10n/translations';

function qs(className: string, root = document) {
	return root.querySelector(className);
}

function qsAll(className: string, root = document) {
	return root.querySelectorAll(className);
}

function getParent(element: any, className: string) {
	while (element && element.parentNode) {
		// eslint-disable-next-line no-param-reassign
		element = element.parentNode;
		if (element.classList && element.classList.contains(className)) return element;
	}
	return false;
}

function textEnding(number: number, txt: string[]) {
	const cases = [2, 0, 1, 1, 1, 2];
	return txt[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

const containsClass = (element: any, className: string) => element.classList.contains(className);

const getFileSize = (size: number) => {
	if (!+size) return '0 Bytes';

	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(size) / Math.log(k));

	return `${parseFloat((size / k ** i).toFixed(0))} ${sizes[i]}`;
};

const getBanDuration = (ban: Ban) => {
	const bannedAt = moment(ban.createdAt);
	const expiresAt = moment(ban.expires);
	let difference;

	if (expiresAt.diff(bannedAt, 'days') > 0)
		// eslint-disable-next-line
		difference = Math.round(expiresAt.diff(bannedAt, 'days', true)) + ' ' + translations.days;
	else if (expiresAt.diff(bannedAt, 'hours') > 0)
		// eslint-disable-next-line
		difference = Math.round(expiresAt.diff(bannedAt, 'hours', true)) + ' ' + translations.hours;
	else if (expiresAt.diff(bannedAt, 'minutes') > 0)
		// eslint-disable-next-line
		difference = Math.round(expiresAt.diff(bannedAt, 'minutes', true)) + ' ' + translations.min;
	return difference;
};

function millisToMinutesOrSeconds(millis: number) {
	const minutes = Math.floor(millis / 60000);
	const seconds = ((millis % 60000) / 1000).toFixed(0);
	return minutes > 0 ? `${minutes}  ${translations.min}` : `${seconds}  ${translations.sec}`;
}

const getRGBValues = (() => {
	const _hex2dec = (v: any) => {
		return parseInt(v, 16);
	};

	const _splitHEX = (hex: string) => {
		let c;
		if (hex.length === 4) {
			c = hex.replace('#', '').split('');
			return {
				r: _hex2dec(c[0] + c[0]),
				g: _hex2dec(c[1] + c[1]),
				b: _hex2dec(c[2] + c[2]),
			};
		}
		return {
			r: _hex2dec(hex.slice(1, 3)),
			g: _hex2dec(hex.slice(3, 5)),
			b: _hex2dec(hex.slice(5)),
		};
	};

	const _splitRGB = (rgb: string) => {
		const c: any = rgb.slice(rgb.indexOf('(') + 1, rgb.indexOf(')')).split(',');

		const obj = {
			r: c[0],
			g: c[1],
			b: c[2],
		};

		return obj;
	};

	const color = (col: any) => {
		const slc = col?.slice(0, 1);
		if (slc && slc === '#') {
			return _splitHEX(col);
		}
		if (slc && slc.toLowerCase() === 'r') {
			return _splitRGB(col);
		}
		return false;
	};

	return {
		color,
	};
})();

const getContrastColor = (color: string) => {
	const rgb = getRGBValues.color(color);
	let contrast = 0;
	if (rgb)
		contrast = (Math.round(rgb.r * 299) + Math.round(rgb.g * 587) + Math.round(rgb.b * 114)) / 1000;
	return contrast >= 125 ? '#000' : '#fff';
};

const isJsonString = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

const checkImageSize = (url: any) => {
	const newImg = new Image();

	const promise = new Promise((resolve, reject) => {
		newImg.onload = () => {
			const width = newImg.naturalWidth;
			const height = newImg.naturalHeight;
			resolve({width, height});
		};

		newImg.onerror = reject;
	});

	newImg.src = window.URL.createObjectURL(url);

	return promise;
};

const isValidUrl = (urlString: string) => {
	const urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // validate protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // validate fragment locator
	return !!urlPattern.test(urlString);
};

const getTopWords = (messages: any[]) => {
	const allMessages = messages.join(' ');
	const words = allMessages.split(/\s+/);
	const wordCount: any = {};
	words.forEach(word => {
		wordCount[word] = (wordCount[word] || 0) + 1;
	});
	const sortedWords = Object.keys(wordCount).sort((a, b) => wordCount[b] - wordCount[a]);
	return sortedWords.slice(0, 100);
};

const getPreviousElementInArray = (array: any[], currentId: number) => {
	const currentIndex = array.findIndex(element => element.id === currentId);

	if (currentIndex === -1 || currentIndex === 0) {
		return null;
	}

	const previousIndex = currentIndex - 1;

	return {...array[previousIndex]};
};

const getNextElementInArray = (array: any[], currentId: number) => {
	const currentIndex = array.findIndex(element => element.id === currentId);

	if (currentIndex === -1 || currentIndex === array.length - 1) {
		return null;
	}

	const nextIndex = currentIndex + 1;

	return {...array[nextIndex]};
};

export {
	qs,
	qsAll,
	getParent,
	containsClass,
	textEnding,
	getFileSize,
	getBanDuration,
	millisToMinutesOrSeconds,
	getContrastColor,
	isJsonString,
	checkImageSize,
	isValidUrl,
	getTopWords,
	getPreviousElementInArray,
	getNextElementInArray,
};

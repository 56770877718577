import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class UserService {
	static encryptChatUserId = async (token: UUID, id: string) => {
		try {
			const apiUrl = apiPath.encryptChatUserId(encodeURIComponent(id));
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

/* eslint-disable max-lines */
import {useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import classNames from 'classnames/bind';

import ModerService from 'services/api/ModerService';
import messagesService from 'store/messagesServices';
import BanService from 'services/api/BanService';

import toastService from 'store/toastServices';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';

import {Message} from 'models/room';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Button} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';

type Option = {
	name: string;
	action: () => void;
	type?: string;
	btns?: any[];
};

interface IChatAvatarSubmenuProps {
	message: Message;
}

const ChatAvatarSubmenu: React.FC<IChatAvatarSubmenuProps> = function ChatAvatarSubmenu({message}) {
	const {id, externalRoomId, talker} = message;

	if (!talker) return null;

	const roomID = externalRoomId || message.room?.externalRoomId;

	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {updateTalkerByTalkerId, updateUserByTalkerId, currentUserId} = useLocalObservable(
		() => messagesService
	);
	const {currentRoomId, setSubmenuAvatar} = useLocalObservable(() => roomServices);
	const {showBanModal} = useLocalObservable(() => modalServices);

	const submenuRef = useRef<HTMLDivElement>(null);
	const [submenuVisible, setSubmenuVisible] = useState(false);
	const [options, setOptions] = useState<Option[]>([]);
	const [submenuAboveMessage, setSubmenuAboveMessage] = useState(true);

	const translations = useL10n();

	const setSubmenuPosition = () => {
		const subMenuHeight = submenuRef.current ? submenuRef.current.clientHeight : 0;
		const chat = document.querySelector('.chat');
		const chatScrollElement = document.querySelector('.chat__scroll');

		let chatPadding = 0;

		if (chat && getComputedStyle(chat))
			chatPadding =
				parseInt(getComputedStyle(chat).marginTop, 10) +
				parseInt(getComputedStyle(chat).paddingTop, 10);

		const messageElement: HTMLElement | null = document.querySelector(
			`.chat__message[data-id="${id}"]`
		);

		if (!messageElement || !chatScrollElement) {
			setSubmenuAboveMessage(true);
			return;
		}

		const offset = chatScrollElement.scrollTop + subMenuHeight + chatPadding;
		const isAboveMessage = messageElement.offsetTop >= offset;

		setSubmenuAboveMessage(isAboveMessage);
	};

	const toggleTalkerModer = async (value: boolean) => {
		const moderData = {
			talkerId: talker.id,
			externalRoomId: currentRoomId || roomID,
			value,
		};
		const response = await ModerService.toggleTalkerModer(moderData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			const updatedTalker = {...talker, isModer: value};
			updateTalkerByTalkerId(updatedTalker);
			// eslint-disable-next-line no-use-before-define
			getOptions();
			addToast({
				title: translations.toasts.user(talker.user.name),
				text: talker.isModer
					? translations.toasts.removeFromModers
					: translations.toasts.addToModers,
			});
		} else if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}

		setSubmenuVisible(false);
		setSubmenuAvatar(null);
	};

	const openBanModal = (type: string) => {
		setSubmenuVisible(false);
		showBanModal({
			type,
			targetName: talker.user?.name,
			targetId: type === 'banUser' ? talker.user?.id : talker.id,
			externalRoomId: currentRoomId || roomID,
		});
	};

	const toggleUserBan = async (value: boolean, days = 0, hours = 0, minutes = 0) => {
		type ban = {
			userId: number;
			value: boolean;
			externalRoomId?: string;
			expires?: string | null;
		};
		const banData: ban = {
			userId: talker.user?.id,
			value,
			externalRoomId: currentRoomId || roomID,
		};
		if (value) {
			const date = new Date();
			date.setDate(date.getDate() + days);
			date.setHours(date.getHours() + hours);
			date.setMinutes(date.getMinutes() + minutes);
			banData.expires = days + hours + minutes > 0 ? date.toISOString() : null;
		}
		const response = await BanService.toggleUserBan(banData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			const {user} = talker;
			addToast({
				title: translations.toasts.user(user.name),
				text: user.bans.length ? translations.toasts.userUnbanned : translations.toasts.userBanned,
			});
		} else if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}

		setSubmenuVisible(false);
		setSubmenuAvatar(null);
	};

	const toggleTalkerBan = async (value: boolean, days = 0, hours = 0, minutes = 0) => {
		type ban = {
			talkerId: number;
			value: boolean;
			externalRoomId: string;
			expires?: string | null;
		};

		const banData: ban = {
			talkerId: talker.id,
			value,
			externalRoomId: currentRoomId || roomID,
		};
		if (value) {
			const date = new Date();
			date.setDate(date.getDate() + days);
			date.setHours(date.getHours() + hours);
			date.setMinutes(date.getMinutes() + minutes);
			banData.expires = days + hours + minutes > 0 ? date.toISOString() : null;
		}
		const response = await BanService.toggleTalkerBan(banData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.user(talker.user.name),
				text: talker.bans.length ? translations.toasts.unBanInRoom : translations.toasts.banInRoom,
			});
		} else if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}

		setSubmenuVisible(false);
		setSubmenuAvatar(null);
	};

	const toggleTalkerMute = async (value: boolean) => {
		const muteData = {
			talkerId: talker.id,
			value,
			externalRoomId: currentRoomId || roomID,
		};

		const response = await BanService.toggleTalkerMute(muteData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			updateTalkerByTalkerId({...talker, isMuted: value});
			addToast({
				title: translations.toasts.user(talker.user.name),
				text: talker.isMuted ? translations.toasts.micUnmuted : translations.toasts.micMuted,
			});
		} else if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}

		setSubmenuVisible(false);
		setSubmenuAvatar(null);
	};

	const goToUserMessages = () => {
		window.open(`/user-messages/${talker.user.id}`, '_blank');
		setSubmenuVisible(false);
		setSubmenuAvatar(null);
	};

	const getOptions = () => {
		let defaultOptions: Option[] = [];

		defaultOptions = [
			{
				name: talker?.isModer ? translations.submenu.unMode : translations.submenu.mode,
				action: talker?.isModer ? () => toggleTalkerModer(false) : () => toggleTalkerModer(true),
			},
		];

		if (!currentUserId) {
			defaultOptions.push({
				name: translations.submenu.allUserMessages,
				action: () => goToUserMessages(),
			});
		}
		if (currentRoomId)
			defaultOptions.push({
				name: talker?.isMuted ? translations.submenu.unMute : translations.submenu.mute,
				action: talker?.isMuted ? () => toggleTalkerMute(false) : () => toggleTalkerMute(true),
				type: talker?.isMuted ? 'success' : 'danger',
			});

		const currentOptions: Option[] = [];

		if (talker.user?.bans.length) {
			currentOptions.push({
				name: translations.submenu.unBan,
				action: () => toggleUserBan(false),
				type: 'success',
			});
		} else {
			currentOptions.push({
				name: translations.submenu.ban,
				action: () => openBanModal('banUser'),
				type: 'danger',
			});
		}
		if (talker?.bans.length) {
			currentOptions.push({
				name: translations.submenu.unBanInRoom,
				action: () => toggleTalkerBan(false),
				type: 'success',
			});
		} else {
			currentOptions.push({
				name: translations.submenu.banInRoom,
				action: () => openBanModal('banTalker'),
				type: 'danger',
			});
		}

		setOptions([...currentOptions, ...defaultOptions]);
	};

	useEffect(() => {
		if (message) {
			setTimeout(() => {
				setSubmenuPosition();
			}, 100);

			setSubmenuVisible(true);
		}
	}, [message]);

	useEffect(() => {
		setOptions([]);
		getOptions();
	}, [message]);

	const chatMessageSubmenuClasses = classNames('chat__message-submenu', {
		'chat__message-submenu--visible': submenuVisible,
		'chat__message-submenu--top': submenuAboveMessage,
	});

	const chatMessageSubmenuItemClasses = (type?: string) => {
		return classNames('chat__message-submenu-item', {
			'chat__message-submenu-item--danger': type === 'danger',
			'chat__message-submenu-item--success': type === 'success',
		});
	};

	return (
		<div ref={submenuRef} className={chatMessageSubmenuClasses}>
			{options.map((option, index) => {
				return (
					<div key={index} className={chatMessageSubmenuItemClasses(option.type)}>
						<button type='button' className='chat__message-submenu-btn' onClick={option.action}>
							{option.name}
						</button>
					</div>
				);
			})}
		</div>
	);
};

export default observer(ChatAvatarSubmenu);
